<script>
  export default {
    name: 'VerifyCVVModal',
    emits: ['validate', 'cancel'],
    props: {
        loader: Boolean,
        isVerified: Boolean
    },
    data(){
        return{
            cvvValue: "",
            missingCVV: false,
        }
    },
    methods: {
        validateCVV() {
            this.checkValidateCreditCardCVV()
            if (this.missingCVV == false) {
                this.$emit('validate', this.cvvValue);
            }
        },
        cancel() {
            this.$emit('cancel');
        },
        checkValidateCreditCardCVV() {
            this.missingCVV = !this.cvvValue
        } 
    },
    mounted() {
    }
  };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click="close">
      <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop=""
      >
        <div class="headImgContainer">
        </div>
        
        <section
          id="modalDescription" class="modalDescription"
        >
        <p>Type in the 3 digit security code (CVV) found on the back of your card</p>
        <img class="headImgContainer" srcset="
                  @/assets/images/cvv-1x.png 1x,
                  @/assets/images/cvv-2x.png 2x
                " src="@/assets/images/cvv-2x.png" aria-hidden="true" />
        </section>

        <footer class="modal-footer">
            <div>
                <input id="creditCardCVV" type="text"  placeholder="000" maxlength="3" v-model="cvvValue"
                @blur="checkValidateCreditCardCVV" aria-required="true"
                aria-labelledby="Credit-Card-CVV" :class="missingCVV
                ? 'll-textfield-error'
                : 'll-textfield'
                " data-test="enroll-verify-cvv" />
                <div v-if="missingCVV" class="form-error-msg error-container" data-test="enroll-verify-cvv-err">
                    <img class="form-error-icon" srcset="
                  @/assets/images/exclaimation-lg.png 1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
                    <div role="alert" aria-describedby="creditCardCVV" class="form-error-txt">
                        Please enter your 3 digit security code
                    </div>
                </div>
                <div v-if="!isVerified" class="form-error-msg error-container" data-test="enroll-verify-cvv-err">
                    <img class="form-error-icon" srcset="
                  @/assets/images/exclaimation-lg.png 1x,
                  @/assets/images/exclaimation-lg-2x.png 2x
                " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
                    <div role="alert" aria-describedby="creditCardCVV" class="form-error-txt">
                        The 3 digit security code (CVV) you entered is incorrect.
                    </div>
                </div>
            </div>
          <button :disabled="loader"
            type="button"
            :class="loader ? 'btn-disabled' : 'btn-yellow'"
            @click="validateCVV"
            aria-label="Submit"
            >
            <span class="img-container">
            <img v-if="loader" class="spinner" srcset="
                  @/assets/images/yellow-spinner.png 1x,
                  @/assets/images/yellow-spinner-2x.png 2x
                " src="@/assets/images/yellow-spinner.png" aria-hidden="true" />
            Submit
            </span>
          </button>
          <button
            type="button"
            class="btn-yellow"
            @click="cancel"
            aria-label="Cancel"
            >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(0, 0, 0, 0.3); */
    background-color: rgba(253, 247, 242, 0.95);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .modal {
    background: #FFFFFF;
    /* box-shadow: inset 0px 1px 1px 0.5px rgba(176, 176, 176, 0.4); */
    box-shadow: rgb(160 170 180 / 40%) 0px 8px 24px;
    border-radius: 3%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 95%;
    max-width: 600px;
    padding: 50px;
  }

  .modal-header,
  .modal-footer {
    display: flex;
    column-gap: 10px;
  }

  .modal-header {
    justify-content: center;
    position: relative;
    font-weight: 700;
    font-size: 25px;
    /* border-bottom: 1px solid #eeeeee; */
    color: #333333;
    text-align: center;
    /* justify-content: space-between; */
  }

  .modal-footer {
    /* border-top: 1px solid #eeeeee; */
    justify-content: center;
    /* flex-direction: column; */
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    text-align: center;
    
    font-style: normal;
    font-weight: 450;
    font-size: 20px;
    line-height: 150%;
    color: black;
    /* or 30px */

    text-align: center;
    letter-spacing: -0.011em;
  }

  .btn-close {
    color: #333333;
    background: $white;
    border: 1px solid $grayLight;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.029em;
    border-radius: 28px;
    height: 40px;
    width: 150px;
  }
    button:hover,
    button:active {
    background-color: $grayLight !important;
}

.btn-disabled {
    border-color: white;
    color: #333333;
    background: $grayLight !important;
    border: 1px solid white;
    border-radius: 2px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.029em;
    border-radius: 10px;
    height: 60px;
    width: 200px;
}

.btn-yellow {
    color: #333333;
    background: #fec443;
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.029em;
    border-radius: 10px;
    height: 60px;
    width: 200px;
  }
    button:hover,
    button:active {
    background-color: #fed67b;
    border-color: white;
}
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

.modalDescription {
    padding: 15px;
}

.ll-textfield {
    height: 60px !important;
    width: 240px;
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 60px;
  width: 240px;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
}

.error-container {
    clear:both
}

.img-container {
  align-items: center !important;
  display: inline-flex !important;
  img {
    margin-top: -1px;
  }
}

.spinner {
  width: 20px !important;
  height: 20px !important;
  margin-right: 3px;
  margin-top: 3px;
  animation: rotation 2s infinite linear;
}
</style>
