<template>
  <div id="forgotUserPage">
    <the-header />
    <ForgotUserNameBG />
    <div class="form-container smaller-header" id="main">
      <form @submit.prevent="validatePage">
        <div class="fuser-note">
          Required fields are indicated by an asterisk (<span class="fhb-red"><strong>*</strong></span>)
          
        </div>

        <div id="email-input">
          <!-- Email Input-->
          <div>
            <label for="email">Email <sup class="fhb-red" aria-hidden="true">*</sup></label>
            <input aria-required="true" name="applicant-email" data-test="forgot-un-email" id="email" type="text"
              v-model="email" v-on:blur="validateEmail" :class="
                missingEmail || invalidEmail ? 'll-textfield-error' : 'll-textfield'
              " placeholder="Enter email address" />
          </div>
          <div id="contextInfo">Please enter the email address associated with your FHB Online Banking profile.</div>
          <div v-if="missingEmail || invalidEmail" class="form-error-msg" data-test="forgot-un-email-err">
            <img class="form-error-icon" srcset="
                @/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
              src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt" aria-label="Email address error">
              Please enter a valid email
            </div>
          </div>
        </div>
        <!-- Empty space to prevent misclick on submit button -->
        <div v-if="!missingEmail && !invalidEmail" class="emptySpace" />

        <!-- Form buttons -->
        <div class="formBtns">
          <button @click="returnToHome" :class="returnHome ? 'processing' : null" id="frgt-username-return-btn"
            type="button" data-test="forgot-un-return">
            {{ returnBtn }}
          </button>
          <button type="submit" :class="allowToRecover ? 'processing' : null" id="frgt-username-submit-btn"
            data-test="forgot-un-submit">
            <img v-if="continueBtn === 'PROCESSING...'" class="spinner" srcset="
                @/assets/images/yellow-spinner.png 1x,
                @/assets/images/yellow-spinner-2x.png 2x
              " src="@/assets/images/yellow-spinner.png" aria-hidden="true" />
            {{ continueBtn }}
          </button>
        </div>
        <Modal v-show="showErrModal" @close="closeModal" />
      </form>
    </div>
    <TheFooter class="fhb-footer" />
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
import Validator from '@/app/validation'
import jQuery from 'jquery'
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import ForgotUserNameBG from '@/components/forgotusernamebg'
import usernameRecovery from '@/app/recovery'
import Modal from "@/components/SomethingWrongModal.vue";

export default {
  name: 'ForgotUsername',
  components: { TheFooter, TheHeader, ForgotUserNameBG, Modal },
  title: "Forgot Username",
  data() {
    return {
      continueBtn: 'CONTINUE',
      returnBtn: 'RETURN TO LOGIN',
      errContainer: [],
      showErr: false,
      allowToRecover: false,
      missingEmail: false,
      invalidEmail: false,
      returnHome: false,
      proxyCallErr: false,
      showErrModal: false,
    }
  },
  computed: {
    ...mapGetters('forgotUsername', [
      'userEmail'
    ]),
    email: {
      get() {
        return this.userEmail
      },
      set(value) {
        this.setUserEmail(value)
      }
    },
  },
  methods: {
    ...mapActions('forgotUsername', [
      'setUserEmail'
    ]),

    // Proxy call always returns 'OK' status
    async postUsernameRecovery(payload) {
      const resp = await usernameRecovery.postUsernameRecovery(payload)

      if (resp && resp.data) {
        if (resp.data.message === 'OK' && this.allowToRecover) {
          console.log('status: ' + resp.status)
          this.$router.push({ name: 'processing-username' })
        } else {
          this.allowToRecover = false
          this.continueBtn = 'CONTINUE'
          this.showErrModal = true
          document.getElementById('email').removeAttribute("disabled");
        }
        // if server times out or no response is received. 
        this.allowToRecover = false
        this.continueBtn = 'CONTINUE'
        this.showErrModal = true
        document.getElementById('email').removeAttribute("disabled");
      }
    },

    closeModal() {
      this.showErrModal = false;
    },

    // validate Email
    validateEmail() {
      // remove any spaces in input
      this.email = this.userEmail.trim().replace(/\s\s+/g, ' ')
      this.missingEmail = !this.email
      if (!this.missingEmail) {
        this.invalidEmail = !Validator.isValidEmail(this.email)
      }
    },

    // check allow to pass before submit
    checkBeforeSubmit() {
      if (
        this.invalidEmail ||
        this.missingEmail
      ) {
        this.allowToRecover = false
      } else {
        this.allowToRecover = true
      }
    },

    // change submit button status
    handleButtonStatus() {
      if (this.allowToRecover) {
        this.continueBtn = 'PROCESSING...'
      } else {
        this.continueBtn = 'CONTINUE'
      }
    },

    // Handles whether or not api call should be made
    // If form is not complete, api call is does not occur
    handleContinue() {
      const payload = {
        email: this.userEmail
      }
      this.proxyCallErr = false
      if (this.allowToRecover) {
        // disable inputs on form on submission
        document.getElementById('email').setAttribute('disabled', '')
        this.postUsernameRecovery(payload)
      }
    },

    // function to return to login and change button status
    returnToHome() {
      this.returnBtn = 'HEADING BACK...'
      this.returnHome = true
      location.href = process.env.VUE_APP_FHB_ONLINE_URL
    },

    // push err input to error container
    pushErrors() {
      if (this.missingEmail || this.invalidEmail) {
        this.errContainer.push('email')
      }
    },

    // validate error to scroll Top or focus on single input
    checkErr() {
      if (
        this.errContainer.length > 0
          ? jQuery('html, body').animate({ scrollTop: 0 })
          : null
      ) {
        this.errContainer = []
      }
      if (this.errContainer.length === 1) {
        document.getElementById(this.errContainer[0]).focus()
        this.errContainer = []
      } else if (this.errContainer.length === 0) {
        this.errContainer = []
      }
    },

    // final validation before submitting form
    validatePage() {
      this.validateEmail()
      this.checkBeforeSubmit()
      this.pushErrors()
      this.checkErr()
      this.handleButtonStatus()
      this.handleContinue()
    }
  },
  mounted() {
    jQuery('html, body').animate({ scrollTop: 0 })
  },
}
</script>
  
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

form {
  max-width: 650px;
  background: white;
  text-align: left;
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  position: relative;
}

label {
  color: #333333;
  display: inline-block;
  margin: 5px 0 15px;
  margin-top: 5px;
  font-weight: bold;
}

input {
  display: block;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  padding: 14px;
}

button {
  height: 55px;
  width: 215px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 20px;
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;

  @include media-breakpoint-down(md) {
    height: 55px;
    width: 185px;
    position: relative;
    background-color: #fec443;
    border: 1px solid white;
    color: #444444;
    cursor: pointer;
    letter-spacing: 0.029em;
    border-radius: 30px;
    margin-top: 20px;
    display: inline-block;
    font-weight: 700;
    line-height: 24px;
    align-items: center;
    text-align: center;
    font-feature-settings: "cpsp" on;
  }
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.formBtns {
  position: relative;
  text-align: center;
  justify-content: center;
}

// to handle return button spacing on smaller devices
#frgt-username-return-btn {
  margin-right: 55px;
  background-color: white;
  border: 1px solid $grayDark;

  @include media-breakpoint-down(md) {
    margin-right: 0px;
  }
}

#frgt-username-return-btn:hover,
#frgt-username-return-btn:active {
  background-color: $grayLight;
}

// to handle continue button spacing on smaller devices
#frgt-username-submit-btn {
  margin-left: 55px;

  @include media-breakpoint-down(sm) {
    margin-left: 0px;
  }
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
}


.fhb-red {
  color: #a1252c;
}

@media (min-height: 1160px) {
  .fhb-footer {
    width: 100%;
    bottom: 0px;
    position: absolute;

  }
}

.processing {
  color: #e7e7e7;
  text-align: center;

  img {
    vertical-align: middle;
  }
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
}

.emptySpace {
  position: relative;
  height: 26.6px;
}

.fuser-note {
  padding-bottom: 20px;
  color: $grayDark;
  @include media-breakpoint-down(sm) {
      text-align: center;
  }
}
.formBtns{
  @include media-breakpoint-down(sm) {
    button{
      width: 100%;
    }
  }
}
#contextInfo{
  padding-top: 10px;
}

</style>
