export default {
  namespaced: true,
  state: {
    user: {
      email: ''
    }
  },
  actions: {
    setUserEmail ({ commit }, email) {
      commit('setUserEmail', email)
    }
  },
  mutations: {
    setUserEmail (state, value) {
      state.user.email = value
    }
  },
  getters: {
    userEmail: (state) => state.user.email
  }
}
