
<template>
    <div id="processingAccount">
        <the-header></the-header>
        <div class="bg-container">
            <div class="bg-orange">
                <h1 class="bg-text">Unlock my Account</h1>
                <div class="svg-container">
                    <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
                        class="coh-inline-element">
                        <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
                    </svg>
                </div>
            </div>
        </div>

        <div class="form-container smaller-header" id="processing-account-page">
            <div class="noti-container">
                <div class="overlay-info">
                    <div class="headImgContainer">
                        <img srcset="@/assets/images/login.png 1x, @/assets/images/login-2x.png 2x" src="@/assets/images/login-2x.png" alt="computer with browser and login fields illustration" />
                    </div>
                    <div class="bg-text1" id="main">
                        <h3 class="bg-text-line">Please check your email to verify your identity</h3>
                        <p class="bg-text-line1">
                            Your request has been submitted. If the information provided is valid, you will receive an email with instructions within a few minutes. If you do not receive an email the
                            information entered was invalid or your account is not locked.
                        </p>
                        <div class="bg-text-line2">
                            <p>
                                Need help? Contact Customer Service
                                <br />
                                Daily 7am–7pm HST
                                <br />
                                (808) 643-4343 (Oahu)
                                <br />
                                (888) 643-4343 (Toll Free)
                            </p>
                        </div>
                    </div>
                    <div class="bottomLinksContainer">
                        <a :href="fhbOnline"
                            id="processing-account-login" test-data="processing-acct-login">Return to Login</a>
                    </div>
                </div>
            </div>
        </div>

        <the-footer class="fhb-footer" />
    </div>
</template>

<script>
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import jQuery from 'jquery'

export default {
    name: 'ProcessingAccount',
    components: { TheHeader, TheFooter },
    data() {
        return {
            fhbOnline: process.env.VUE_APP_FHB_ONLINE_URL
        }
    },
    mounted() {
        jQuery('html, body').animate({ scrollTop: 0 })
    }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.headImgContainer {
    position: relative;
    height: auto;
    text-align: center;
}

img {
    padding-top: 30px;
    padding-bottom: 15px;
    height: auto;
    width: 93.5px;
}

.bg-text-line {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 10px;
    text-align: center;
}

.bg-text1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bg-text-line1 {
    margin: 0 0 0 0;
    font-weight: 450;
    font-size: 15.5px;
    text-align: center;
}

.bg-text-line2 {
    margin: 0 0 0 0;
    font-weight: 450;
    font-size: 15.5px;
    text-align: center;
}

.bottomLinksContainer {
    display: flex;
    justify-content: center;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
}

.bottomText {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
}

.bottomTextLine {
    margin-top: 0px;
    margin-bottom: 0px;
}
</style>
