<template>
  <div id="accountrecovery">
    <the-header />
    <div class="bg-container">
      <div class="bg-orange">
        <h1 class="bg-text">Unlock My Account</h1>
        <div class="svg-container">
          <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
            class="coh-inline-element">
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>

    <div class="form-container smaller-header" id="main">
      <form @submit.prevent="validatePage">
        <!--Form header-->
        <div class="formHeader">
          Your account has been locked to keep it safe. Unlock it yourself in a few minutes.
        </div>
        <div class="ar-note">
          Required fields are indicated by an asterisk (<span class="fhb-red"><strong>*</strong></span>)
        </div>

        <!-- dividing for empty spacing and to prevent misclicks with global error message -->
        <div v-if="!showErr" class="globalErrSpace">
        </div>
        <div v-else-if="showErr" class="page-error-container" id="account-recovery-page-errors" data-test="ar-page-err">
          <div class="page-error-icon-container">
            <img class="page-error-icon" srcset="
                        @/assets/images/exclaimation-lg.png 1x,
                        @/assets/images/exclaimation-lg-2x.png 2x
                      " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
          </div>

          <div role="alert" class="page-error-msg">
            You must complete or correct all required fields to proceed
          </div>
        </div>

        <!-- Account Number input -->
        <div id="acc-num-input">
          <label class="topLabel" >Account Number 
            <sup class="fhb-red"
              aria-hidden="true">*</sup>
            <span class="i-btn" @click="showExtendContext" @keyup.space="showExtendContext" tabindex="0">
              <img v-if="!extendContext" class="i-img" srcset="
                    @/assets/images/i.png 1x,
                    @/assets/images/ix2.png 2x
                  " src="@/assets/images/ix2.png" aria-hidden="true" />
              <img v-if="extendContext" class="i-img" srcset="
                    @/assets/images/i-selected.png 1x,
                    @/assets/images/i-selectedx2.png 2x
                  " src="@/assets/images/i-selectedx2.png" aria-hidden="true" />
            </span>
          </label>
          <span v-if="extendContext" class="extendPop">
            If providing a line of credit account, please include the -1 at the end of the number. Debit card numbers are not accepted.
          </span>
          <input id="account-number" placeholder="Enter your account number" type="text" v-model.trim="accountNum"
            v-on:blur="validateAccountNum" aria-required="true" name="account-number" autocomplete="off" :class="missingAcctNum || invalidAcctNum ? 'll-textfield-error' : 'll-textfield'
              " data-test="ar-acct-num" />
          <div v-if="(missingAcctNum || invalidAcctNum)" class="form-error-msg" data-test="ar-acct-num-err">
            <img class="form-error-icon" srcset="
                                  @/assets/images/exclaimation-lg.png 1x,
                                  @/assets/images/exclaimation-lg-2x.png 2x
                              " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt" aria-label="Account number error">
              Please enter a valid account number
            </div>
          </div>
        </div>
        <!-- Empty space to prevent misclick on submit button -->
        <div v-if="(!missingAcctNum && !invalidAcctNum)" class="emptySpace" />

        <div id="login-id-input">
          <!-- Locked login ID input -->
          <label>Username <sup class="fhb-red" aria-hidden="true">*</sup></label>
          <input aria-required="true" data-test="ar-user-id" name="user-loginID" maxlength="32" minlength="6"
            class="re-textfield arec-login" id="loginID" type="text" v-on:blur="validateLoginId" v-model="loginID" :class="missingLoginID || invalidLoginID ? 'll-textfield-error' : 'll-textfield'
              " placeholder="Enter your username" />
          <div v-if="missingLoginID || invalidLoginID" class="form-error-msg" data-test="ar-user-id-err">
            <img class="form-error-icon" srcset="
                                  @/assets/images/exclaimation-lg.png 1x,
                                  @/assets/images/exclaimation-lg-2x.png 2x
                                  " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt" aria-label="Username error">
              Please enter a valid username
            </div>
          </div>
        </div>
        <!-- Empty space to prevent misclick on submit button -->
        <div v-if="!missingLoginID && !invalidLoginID" class="emptySpace" />

        <!-- Email input -->
        <div id="email-input">
          <label>Email Address <sup class="fhb-red" aria-hidden="true">*</sup></label>
          <input aria-required="true" aria-labelledby="applicant-email" data-test="ar-email" name="applicant-email"
            placeholder="Enter email address" class="re-textfield arece-email" id="email" type="text" v-model="email"
            v-on:blur="validateEmail" :class="missingEmail || invalidEmail ? 'll-textfield-error' : 'll-textfield'
              " />
          <div v-if="missingEmail || invalidEmail" class="form-error-msg" data-test="ar-email-err">
            <img class="form-error-icon" srcset="
                                  @/assets/images/exclaimation-lg.png 1x,
                                  @/assets/images/exclaimation-lg-2x.png 2x
                                  " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt" aria-label="Email address error">
              Please enter a valid email address
            </div>
          </div>
        </div>
        <!-- Empty space to prevent misclick on submit button -->
        <div v-if="!missingEmail && !invalidEmail" class="emptySpace" />

        <div class="bottomContext">If you do not have this information, please contact us at <span class="tel">(888)
            643-4343</span> for assistance.</div>

        <!-- Form buttons -->
        <div class="formBtns">
          <button @click="returnToHome" :class="returnHome ? 'processing' : null" id="account-recovery-return-btn"
            type="button" data-test="ar-return">
            {{ returnBtn }}
          </button>
          <button type="submit" :class="allowToRecover ? 'processing' : null" id="account-recovery-submit-btn"
            data-test="ar-submit">
            <img v-if="continueBtn === 'PROCESSING...'" class="spinner" srcset="
                @/assets/images/yellow-spinner.png 1x,
                @/assets/images/yellow-spinner-2x.png 2x
              " src="@/assets/images/yellow-spinner.png" aria-hidden="true" />
            {{ continueBtn }}
          </button>
        </div>
        <Modal v-show="showErrModal" @close="closeModal" />
        <DisabledAccountModal v-show="disabledModal" @close="closeModal" />
      </form>
    </div>

    <the-footer />
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Validator from '@/app/validation'
import jQuery from 'jquery'
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import accountRecovery from '@/app/recovery'
import Modal from "@/components/SomethingWrongModal.vue";
import DisabledAccountModal from '@/components/DisabledAccountModal.vue'

export default {
  name: 'AccountRecovery',
  components: { TheFooter, TheHeader, Modal, DisabledAccountModal },
  title: "Account Recovery",
  data() {
    return {
      continueBtn: 'CONTINUE',
      returnBtn: 'RETURN TO LOGIN',
      errContainer: [],
      missingAcctNum: false,
      invalidAcctNum: false,
      missingEmail: false,
      invalidEmail: false,
      missingLoginID: false,
      invalidLoginID: false,
      showErr: false,
      allowToRecover: false,
      returnHome: false,
      showErrModal: false,
      disabledModal: false,
      extendContext: false,
    }
  },
  computed: {
    ...mapGetters('accountRecovery', [
      'userAccountNum',
      'userEmail',
      'userLoginID'
    ]),
    accountNum: {
      get() {
        return this.userAccountNum
      },
      set(value) {
        this.setUserAccountNum(value)
      }
    },
    email: {
      get() {
        return this.userEmail
      },
      set(value) {
        this.setUserEmail(value)
      }
    },
    loginID: {
      get() {
        return this.userLoginID
      },
      set(value) {
        this.setUserLoginID(value)
      }
    }
  },
  methods: {
    ...mapActions('accountRecovery', [
      'setUserAccountNum',
      'setUserEmail',
      'setUserLoginID'
    ]),

    // Proxy call for account verification. 
    // Will send an email confirmation to the user if their account exists
    // User will be navigated to the processing account page regardless
    async postAccountVerifyData(payload) {
      const resp = await accountRecovery.postAccountVerifyData(payload)
      if (resp && resp.data) {
        // If account is disabled and trying to be unlocked
        if (resp.data.response !== "Active" && this.allowToRecover && resp.status === 200) {
          this.allowToRecover = false
          this.continueBtn = 'CONTINUE'
          document.getElementById('account-number').removeAttribute("disabled");
          document.getElementById('email').removeAttribute("disabled");
          document.getElementById('loginID').removeAttribute("disabled");
          this.disabledModal = true
        }
        else if (resp.data.response === 'Active' && this.allowToRecover && resp.status === 200) {
          this.$router.push({ name: 'processing-account' })
        } else {
          // reset form if BE proxy call fails
          this.allowToRecover = false
          this.continueBtn = 'CONTINUE'
          document.getElementById('account-number').removeAttribute("disabled");
          document.getElementById('email').removeAttribute("disabled");
          document.getElementById('loginID').removeAttribute("disabled");
          this.showErrModal = true
        }
      } else {
        // if server times out or if no response is received
        this.allowToRecover = false
        this.continueBtn = 'CONTINUE'
        document.getElementById('account-number').removeAttribute("disabled");
        document.getElementById('email').removeAttribute("disabled");
        document.getElementById('loginID').removeAttribute("disabled");
        this.showErrModal = true
      }
    },
    showExtendContext(event) {
      event.stopPropagation()
      this.extendContext = !this.extendContext
    },

    closeModal() {
      this.showErrModal = false;
      this.disabledModal = false
    },
    // Validate account number
    validateAccountNum() {
      this.missingAcctNum = !this.accountNum
      if (this.missingAcctNum) {
        this.invalidAcctNum = true
      } else {
        this.invalidAcctNum = false
      }
      this.checkForErrors()
    },

    // validate Email
    validateEmail() {
      this.email = this.email.trim().replace(/\s\s+/g, ' ')
      this.missingEmail = !this.email
      if (!this.missingEmail) {
        this.invalidEmail = !Validator.isValidEmail(this.email)
      }
      this.checkForErrors()
    },

    // validate loginId
    validateLoginId() {
      this.missingLoginID = !this.loginID
      if (!this.missingLoginID) {
        this.invalidLoginID = !Validator.isNotValidLoginID(this.loginID)
      }
      this.checkForErrors()
    },

    // check allow to pass before submit
    checkBeforeSubmit() {
      if (
        this.missingAcctNum ||
        this.invalidAcctNum ||
        this.missingEmail ||
        this.invalidEmail ||
        this.missingLoginID ||
        this.invalidLoginID
      ) {
        this.allowToRecover = false
      } else {
        this.allowToRecover = true
      }
    },

    // check for errors on blur for all inputs. removes global message if no errors are present on the form
    checkForErrors() {
      if (
        this.missingAcctNum ||
        this.invalidAcctNum ||
        this.missingEmail ||
        this.invalidEmail ||
        this.missingLoginID ||
        this.invalidLoginID
      ) {
        this.showErr = true
      } else {
        this.showErr = false
      }
    },

    // check if to show global Error message
    errCnt() {
      if (!this.allowToRecover) {
        this.showErr = true
      } else {
        this.showErr = false
      }
    },
    // change submit button status
    handleButtonStatus() {
      if (this.allowToRecover) {
        this.continueBtn = 'PROCESSING...'
      } else {
        this.continueBtn = 'CONTINUE'
      }
    },

    // Handles whether or not api call should be made
    // If form is not complete, api call is does not occur
    handleContinue() {
      const payload = {
        account: this.userAccountNum.padStart(10, '0'),
        username: this.userLoginID,
        email: this.userEmail
      }
      // console.log('Payload: ' + JSON.stringify(payload, null, 2))
      if (this.allowToRecover) {
        this.showErr = false
        // disable fields on submission if fields are error-free
        document.getElementById('account-number').setAttribute('disabled', '')
        document.getElementById('email').setAttribute('disabled', '')
        document.getElementById('loginID').setAttribute('disabled', '')
        this.postAccountVerifyData(payload)
      }
    },

    // function to return to login and change button status
    returnToHome() {
      this.returnBtn = 'HEADING BACK...'
      this.returnHome = true
      location.href = process.env.VUE_APP_FHB_ONLINE_URL
    },

    // push err input to error container
    pushErrors() {
      if (this.missingAcctNum || this.invalidAcctNum) {
        this.errContainer.push('account-number')
      }
      if (this.missingEmail || this.invalidEmail) {
        this.errContainer.push('email')
      }
      if (this.missingLoginID || this.invalidLoginID) {
        this.errContainer.push('loginID')
      }
    },

    // validate error to scroll Top or focus on single input
    checkErr() {
      if (
        this.errContainer.length > 1
          ? jQuery('html, body').animate({ scrollTop: 0 })
          : null
      ) {
        this.errContainer = []
      }
      if (this.errContainer.length === 1) {
        document.getElementById(this.errContainer[0]).focus()
        this.errContainer = []
      } else if (this.errContainer.length === 0) {
        this.errContainer = []
      }
    },

    // final validation before submit
    validatePage() {
      this.validateAccountNum()
      this.validateEmail()
      this.validateLoginId()
      this.checkBeforeSubmit()
      this.errCnt()
      this.pushErrors()
      this.checkErr()
      this.handleButtonStatus()
      this.handleContinue()
    }
  },
  mounted() {
    jQuery('html, body').animate({ scrollTop: 0 })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.tel {
  white-space: nowrap;
}

.bottomContext {
  padding-top: 30px;
  font-size: 17px;
  text-align: center;
}

input {
  display: block;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  padding: 14px;
}

button {
  height: 55px;
  width: 215px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 50px;
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;

  @include media-breakpoint-down(sm) {
    height: 55px;
    width: 185px;
    position: relative;
    background-color: #fec443;
    border: 1px solid white;
    color: #444444;
    cursor: pointer;
    letter-spacing: 0.029em;
    border-radius: 30px;
    margin-top: 20px;
    display: inline-block;
    font-weight: 700;
    line-height: 24px;
    align-items: center;
    text-align: center;
    font-feature-settings: "cpsp" on;
  }
}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}


.formBtns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  // position: relative;
  // text-align: center;
  @include media-breakpoint-down(sm) {
    button {
      width: 100%;
    }

    justify-content:none;
  }

  @include media-breakpoint-down(md) {
    button {
      width: 100%;
    }

    justify-content:none;
  }
}

// to handle return button spacing on smaller devices
#account-recovery-return-btn {
  margin-right: 55px;
  background-color: white;
  border: 1px solid $grayDark;

  @include media-breakpoint-down(md) {
    margin-right: 20px;
  }
}

#account-recovery-return-btn:hover,
#account-recovery-return-btn:active {
  background-color: $grayLight;
}

// to handle continue button spacing on smaller devices
// #account-recovery-submit-btn {
//   margin-left: 55px;

//   @include media-breakpoint-down(md) {
//     margin-left: 20px;
//   }
// }

::placeholder {
  color: #bbbbbb;
  opacity: 1;
}

.fhb-red {
  color: #a1252c;
}


.processing {
  color: #e7e7e7;
  text-align: center;

  img {
    vertical-align: middle;
  }
}

.heading {
  padding-top: 50px;
  text-align: center;
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 1px 1.5px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
}

.emptySpace {
  position: relative;
  height: 26.6px;
}

.globalErrSpace {
  position: relative;
  height: 60px;
  padding: 44px 0;
}

.formHeader {
  font-style: normal;
  text-align: center;
  font-size: 26px;
  padding-top: 10px;
  font-weight: 450;
}

.ar-note {
  text-align: center;
  color: $grayDark;
  margin-bottom: -26px;
  margin-top: 5px;
}

@media (max-width: 579px) {
  #account-recovery-submit-btn {
    margin-left: 0px;
  }

  #account-recovery-return-btn {
    margin-right: 0px;
  }
}

.topLabel {
  margin-top: 0.2em;
}

.extendPop {
  // border: 1px solid black;
  display: block;
  box-shadow: 0px 0px 5px 3px rgba(175, 175, 175, 0.25);
  border-radius: 6px;
  padding: 8px 15px;
  width: calc(80% - 2rem);
  margin-left: 30px;
  position: absolute;
  z-index: 90;
  background-color: white;
}

.i-img {
  vertical-align: middle;
}

.i-btn {

}
</style>
