<template>
  <div id="visitbranch">
    <the-header></the-header>
    <div class="bg-container">
      <div class="bg-orange">
        <h1 class="bg-text">Enroll in Online Banking</h1>
        <div class="svg-container">
          <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
            class="coh-inline-element">
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>
    <div class="form-container" id="visit-branch-page">
      <form>
        <div class="headImgContainer">
          <img srcset="@/assets/images/sadface.png 1x, @/assets/images/sadface-2x.png 2x" src="@/assets/images/sadface-2x.png" alt="browser with sad face illustration" />
        </div>
        <div class="bg-text1">
          <p class="bg-text-line1">We are unable to enroll you in online banking at this time.</p>
        </div>
        <div class="bg-text2">
          <p class="bg-text-line2">
            Please contact us at (808) 643-4343 or toll-free (888) 643-4343, Monday-Friday 8am-7pm HST.
          </p>
        </div>

        <div class="back-container">
          <!-- <a class="back-link" href="javascript:history.back()" data-test="nav-back"> -->
          <button class="back-link" id="backLink" @click="backRoute" data-test="nav-back">
            Back
          </button>
        </div>
      </form>
    </div>

    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
export default {
  name: 'visitBranch',
  components: { TheHeader, TheFooter },
  mounted() {        
        if (window.history && window.history.pushState){
        window.addEventListener('popstate', this.backChange, false)
      }
  },
  methods: {
    backRoute() {
      this.$router.push('/enroll')
    },
    backChange() {
            // location.reload()
            this.$router.push({ path: "/enroll"})
            // console.log("reload???????")
        },
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
.back-link{
  border: none;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 450;
  cursor: pointer;
  width: 50px;
  height: 32px;;
  background-color: #ffffff;
  color:#333333;
  margin-top: 0px;
}
#backLink:hover{
  background-color: #ffffff;
  color: #811E24;
}

.back-container {
  margin-top: 3em;
  text-align: center;
}

.back-container a {
  margin: auto;

  &:hover,
  &:focus,
  &:active {
    color: $redDark;
  }
}

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

img {
  padding-top: 30px;
  padding-bottom: 15px;
  height: auto;
  width: 93.5px;
}

.bg-text {
  @include media-breakpoint-up(lg) {
    padding-top: 1em;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 1em;
    padding-top: 1.5em;
  }
}

.bg-text-line1 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 1.4rem;
  text-align: center;
}

.bg-text2 {
  //font-size: 1rem;
  padding: 20px 0;
  text-align: center;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 0.25em;
  }
}

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 40px;
  display: inline-block;
  // font-family: "CircularXX TT";
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

/* a:hover,
a:active, */
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.submitBtn {
  position: relative;
  text-align: center;
}

form {
  @include media-breakpoint-up(sm) {
    max-width: 500px;
  }
}

.form-container {
  margin-top: 0;
  @include media-breakpoint-up(sm) {
    margin-top: -240px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -250px;
  }
}
</style>
