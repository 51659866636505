<template>
    <div class="loading-container">
        <div class="page-bg">
            <div class="content-container">
                <div class="overlay-content">
                    <div class="overlay-splash">
                        <div class="spinner">
                            <img srcset="@/assets/images/half-circle.png 1x, @/assets/images/half-circle-2x.png 2x" src="@/assets/images/half-circle-2x.png" alt="" />
                        </div>
                        <div class="logo">
                            <img srcset="@/assets/images/fhb-mark.png 1x, @/assets/images/fhb-mark-2x.png 2x" src="@/assets/images/fhb-mark-2x.png" alt="FHB logo" />
                        </div>
                    </div>
                    <div class="v-spacer-15"></div>
                    <div class="overlay-body">
                        <h1>Loading...</h1>
                        <p>Please wait while the page loads.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'Loading-Icon',
    props: {
        type: String
    },
    components: {

    }
}
</script>
  
<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.loading-container {
    min-height: 100vh;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}

.placeholder {
    height: 100%;
    margin: auto;
    max-width: 600px;
    min-height: 100vh;
    padding: 4em 0;
    position: static;
    width: 100%;
    z-index: 2;
}

.overlay-content {
    @include media-breakpoint-down(sm) {
        background-color: $white;
    }
}

.page-bg {
    background-color: fade-out($orangeFaint, 0.15);
    min-height: 100vh;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
}

.content-container {
    align-items: center;
    height: 100vh;
}

.loading-content {
    height: auto;
    margin: 2em auto;
    padding: 2em;
    text-align: center;

    @include media-breakpoint-up(sm) {
        max-width: 500px;
        width: calc(100% - 4em);
    }

    @include media-breakpoint-up(lg) {
        max-width: 700px;
    }
}

.overlay-body {
    margin: 0.5em auto;
    font-weight: 700;
    text-align: center;

    h1 {
        font-size: 1.8rem;
        margin: 0;
    }

    p {
        font-weight: normal;
    }
}

.spinner img {
    -webkit-animation: spin 5s linear infinite;
    -moz-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
}

@-moz-keyframes spin {
    80% {
        -moz-transform: rotate(180deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@-webkit-keyframes spin {
    80% {
        -webkit-transform: rotate(180deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes spin {
    80% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

.overlay-splash {
    margin: 2em auto 0;
    position: relative;
    width: 80px;
    z-index: 2;
}

.overlay-splash .spinner {
    height: 80px;
    position: absolute;
    top: 4px;
    width: 80px;
    z-index: 10;
}

.overlay-splash .logo {
    z-index: 1;
    position: relative;
}

.overlay-splash .spinner img {
    height: auto;
    width: 80px;
}

.overlay-splash .logo img {
    height: auto;
    width: 60px;
    animation: fadeIn 5s linear infinite;
    -webkit-animation: fadeIn 5s linear infinite;
    -moz-animation: fadeIn 5s linear infinite;
    -o-animation: fadeIn 5s linear infinite;
    -ms-animation: fadeIn 5s linear infinite;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.overlay-body {
    position: relative;
    z-index: 40;
}
</style>
  