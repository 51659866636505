<template>
  <div id="profilepage">
    <the-header></the-header>
    <HomepageBg></HomepageBg>
    <div class="form-container" id="profile-info-page">
      <form @submit.prevent="validationPage" id="main">
        <div id="personal-page-errors" />

        <div v-if="showErr" class="page-error-container" data-test="pi-page-err">
          <div class="page-error-icon-container">
            <img class="page-error-icon" srcset="
                @/assets/images/exclaimation-lg.png 1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
          </div>
          <div role="alert" class="page-error-msg">
            You must complete or correct all required fields to proceed
          </div>
        </div>

        <div class="top-text">
          <small class="contentText">Please provide the following contact information.</small>
        </div>

        <!-- Email input -->
        <div v-if="!applicantEmail" class="ll-item ll-lname">
          <label for="applicant-email" id="applicant-email">Email <sup class="fhb-red">*</sup></label>
          <input :disabled="allowToPass ? true : false" aria-required="true" aria-labelledby="applicant-email"
            name="applicant-email" class="ll-textfield tf-lname" id="email" data-test="pi-email" type="text"
            v-model="email" v-on:blur="validateEmail" :class="
              missingEmail || invalidEmail
                ? 'll-textfield-error'
                : 'll-textfield'
            " />
          <div v-if="missingEmail || invalidEmail" class="form-error-msg" data-test="pi-email-err">
            <img class="form-error-icon" srcset="
                @/assets/images/exclaimation-lg.png 1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt">
              Please enter a valid email address
            </div>
          </div>
          <div v-else-if="!missingEmail || !invalidEmail" class="emptySpace"></div>
        </div>
         <!-- Showing extra info if email exist in core -->
        <!-- <div v-if="!showErr" class="emptySpace"></div> -->
        <div v-if="applicantEmail" class="emailText">
            <!-- <div > -->
              <label class="emailTextTitle">Email</label>
              <div class="contentText">We already have your email on file. We will use this to send you a secure access code to verify your identity.</div>
              <div class="contentInfo">{{ applicantEmail }}</div>
            <!-- </div> -->
        </div>
        <!-- <PhoneNumber Input /> -->
        <div class="ll-item" v-if="!applicantPhone">
          <label for="phone-number" id="phone-number" class="phone-number-label">Phone Number (mobile recommended) <sup
              class="fhb-red">*</sup></label>
          <Phone-input :disabled="allowToPass ? true : false" id="phoneNumber" aria-labelledby="phone-number"
            name="app-phone" maxlength="10" v-model="phoneNumber" aria-required="true" data-test="pi-phone"
            v-on:blur="validatePhoneNumber" :class="
              missingPhoneNumber || invalidPhoneNumber
                ? 'll-textfield-error'
                : 'll-textfield'
            "></Phone-input>
          <div v-if="missingPhoneNumber" class="form-error-msg" data-test="pi-phone-missing">
            <img class="form-error-icon" srcset="
                @/assets/images/exclaimation-lg.png 1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt">
              Please provide a U.S. phone number
            </div>
          </div>
          <div v-else-if="invalidPhoneNumber" class="form-error-msg" data-test="pi-phone-invalid">
            <img class="form-error-icon" srcset="
                @/assets/images/exclaimation-lg.png 1x,
                @/assets/images/exclaimation-lg-2x.png 2x
              " src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
            <div role="alert" class="form-error-txt">
              Please provide a U.S. phone number
            </div>
          </div>
          <div v-else-if="!missingPhoneNumber && !invalidPhoneNumber" class="emptySpace"></div>
        </div>

            <!-- Showing extra info if phone exist in core -->
            <div v-if="applicantPhone" class="phoneText">
              <label class="textTitle">Phone Number</label>
              <div class="contentText">We already have your phone number on file. We will use this to send you a secure access code to verify your identity.</div>
              <div class="contentInfo">{{ applicantPhone }}</div>
            </div>

        <div v-if="!showErr" class="emptySpace"></div>
        <div v-if="applicantPhone || applicantEmail" class="bottomText"><span>You will be able to update your security preferences once you sign on.</span></div>
        <div v-if="!showErr" class="emptySpace"></div>
        <div class="buttons">
          <button class="backBtn" type="button" @click="backRoute" id="profile-info-back-btn" data-test="pi-back-link">Back</button>
          <!-- <a class="backBtn" @click="backRoute" id="profile-info-back-btn" data-test="pi-back-link">BACK</a> -->
          <!-- <a class="backBtn" href='javascript:;' @click="backRoute"  id="profile-info-back-btn" data-test="pi-back-link">Back</a> -->

          <div class="submitBtn">
            <button type="submit" id="profile-info-submit-btn" :class="allowToPass ? 'processing' : null"
              @click="handleCTN" data-test="pi-submit-btn">
              <img v-if="submitBtn === 'PROCESSING...'" class="spinner" srcset="
                @/assets/images/yellow-spinner.png 1x,
                @/assets/images/yellow-spinner-2x.png 2x
              " src="@/assets/images/yellow-spinner.png" aria-hidden="true" />
              {{ submitBtn }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
/* eslint-disable space-before-function-paren */
import { mapGetters, mapActions } from 'vuex'
import Validator from '@/app/validation'
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import HomepageBg from '@/components/homepagebg'
import PhoneInput from '@/components/CustomInputs/phoneinput.vue'
import jQuery from 'jquery'
export default {
  name: 'profile-info',
  components: { TheHeader, TheFooter, HomepageBg, PhoneInput },
  created() {   
      window.addEventListener('beforeunload', function(event) {
         event.returnValue = console.log('Trying to reload page')
      })
    },
  data() {
    return {
      submitBtn: 'CONTINUE',
      errContainer: [],
      missingEmail: false,
      invalidEmail: false,
      invalidPhoneNumber: false,
      missingPhoneNumber: false,
      showErr: false,
      allowToPass: false,
      errEmail: false,
      errPhone: false,
      phoneNumber1: ''
    }
  },

  computed: {
    ...mapGetters('profileInfo', ['applicantSubmitEmail', 'applicantPhoneNum']),
    ...mapGetters('enrollhome', ['applicantPhone', 'applicantEmail']),
    ...mapGetters('mfaData', ['token']),
    email: {
      get() {
        return this.applicantSubmitEmail
      },
      set(value) {
        this.setApplicantEmail(value)
      }
    },
    phoneNumber: {
      get() {
        return this.applicantPhoneNum
      },
      set(value) {
        this.setPhoneNum(value)
      }
    },
    tokenAccess:{
      get() {
        return this.token
      }
    }
  },
  methods: {
    ...mapActions('profileInfo', ['setApplicantEmail', 'setPhoneNum']),

    // Back to last page
    backRoute() {
      this.$router.push('/enroll')
    },
    // validate Email
    validateEmail() {
      this.email = this.email.trim().replace(/\s\s+/g, ' ')
      this.missingEmail = !this.email
      if (!this.missingEmail) {
        this.invalidEmail = !Validator.isValidEmail(this.email)
      }
      if (!this.applicantEmail) {
        if (this.missingEmail || this.invalidEmail) {
          this.errEmail = true
        }
        if (!this.missingEmail && !this.invalidEmail) {
          this.errEmail = false
        }
      }
      this.checkErrCount()
    },

    // validate Phone Number
    validatePhoneNumber() {
      this.missingPhoneNumber = !this.phoneNumber
      const inputPhoneNum = this.phoneNumber
        .replace('(', '')
        .replace(')', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')
        .replace('+1', '')
      const regex =
        // eslint-disable-next-line no-useless-escape
        /^\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^\d{10}$|^1\s\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^(1\s?)?\(\d{3}\)(\s|\-)?\d{3}\-\d{4}$/
      if (!regex.test(inputPhoneNum)) {
        this.invalidPhoneNumber = true
      } else {
        this.invalidPhoneNumber = false
      }
      if (!this.applicantPhone) {
        if (this.missingPhoneNumber || this.invalidPhoneNumber) {
          this.errPhone = true
        }
        if (!this.missingPhoneNumber && !this.invalidPhoneNumber) {
          this.errPhone = false
        }
      }
      this.checkErrCount()
    },

    //check global err message for every single input field
    checkErrCount() {
      if (!this.applicantEmail && !this.applicantPhone) {
        if (!this.errEmail && !this.errPhone) {
          this.showErr = false
        } else {
          this.showErr = true
        }
      }
      if (this.applicantEmail && !this.applicantPhone) {
        if (!this.errPhone) {
          this.showErr = false
        } else {
          this.showErr = true
        }
      }
      if (!this.applicantEmail && this.applicantPhone) {
        if (!this.errEmail) {
          this.showErr = false
        } else {
          this.showErr = true
        }
      }

    },

    checkBeforeSubmit() {
      if (!this.applicantEmail && this.applicantPhone) {
        if (this.invalidEmail || this.missingEmail) {
          this.allowToPass = false
        } else {
          this.allowToPass = true
        }
      }
      if (this.applicantEmail && !this.applicantPhone) {
        if (this.missingPhoneNumber || this.invalidPhoneNumber) {
          this.allowToPass = false
        } else {
          this.allowToPass = true
        }
      }
      if (!this.applicantEmail && !this.applicantPhone) {
        if (
          this.invalidEmail ||
          this.missingEmail ||
          this.missingPhoneNumber ||
          this.invalidPhoneNumber
        ) {
          this.allowToPass = false
        } else {
          this.allowToPass = true
        }
      }

    },
    // if allow to pass = ture, redirect to next page
    checkRedirect() {
      if (this.allowToPass) {
        setTimeout(() => {
          this.$router.push('/enhancedsecurity')
        }, 1000)
      }
    },

    // check if to show global Error message
    errCnt() {
      if (!this.allowToPass) {
        this.showErr = true
      } else {
        this.showErr = false
      }
    },

    // change submit button status
    handleCTN() {
      if (this.allowToPass) {
        this.submitBtn = 'PROCESSING...'
      } else {
        this.submitBtn = 'CONTINUE'
      }
    },

    // push err input to error container
    pushErrors() {
      if (!this.applicantEmail) {
        if (this.missingEmail || this.invalidEmail) {
          this.errContainer.push('email')
        }
      }
      if (!this.applicantPhone) {
        if (this.invalidPhoneNumber || this.missingPhoneNumber) {
          this.errContainer.push('phoneNumber')
        }
      }

    },

    // validate error to scroll Top or focus on single input
    checkErr() {
      if (this.errContainer.length > 1
      ) {
        jQuery('html, body').animate({ scrollTop: 0 })
        this.errContainer = []
      }
      if (
        this.errContainer.length == 1
      ) {
        document.getElementById(this.errContainer[0]).focus()
        this.errContainer = []
      } else if (this.errContainer.length === 0) {
        this.errContainer = []
      }
    },


    // final validation before submit
    validationPage() {
      this.validateEmail()
      this.validatePhoneNumber()
      this.checkBeforeSubmit()
      this.errCnt()
      this.pushErrors()
      this.checkErr()
      this.handleCTN()
      this.checkRedirect()
      // console.log(`phone: ${this.phoneNumber}`)
    }
  },

  mounted() {
    jQuery('html, body').animate({ scrollTop: 0 })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.bottomText{
  margin-top: 10px;
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;

color: #333333; 
}
.contentText{
  font-style: normal;
  font-weight: 450;
  font-size: 18px;
  line-height: 150%;
/* or 27px */

letter-spacing: -0.011em;

color: #333333; 
}
.contentInfo{
  padding-top: 10px;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
}
.textTitle{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1.1%;
  color: #333333;
  margin-bottom: 5px;
  margin-top: 5px;
}
.emailTextTitle{
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -1.1%;
  color: #333333;
  margin-bottom: 5px;
  margin-top: 15px;
}
.emailText{
  // padding-top: 20px;
  padding-bottom: 30px;
}

.page-error-container{
  margin-bottom: 10px;
  margin-top: 0;
}

.top-text {
  font-weight: 450;
  font-size: 20px;
  padding-bottom: 10px;
}

form {
  max-width: 650px;
  background: white;
  text-align: left;
  padding: 45px;
  padding-left: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  position: relative;
}

label {
  color: #333333;
  display: inline-block;
  margin: 5px 0 15px;
  margin-top: 40px;
  /* letter-spacing: 1px; */
  font-weight: bold;
}

input {
  display: block;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  padding: 19px;
  // margin-bottom: 30px;
}

input[type="radio"] {
  display: inline-block;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 18px;
  box-shadow: none;
  margin-right: 15px;
  background-color: #fec443;
}

button {
  @include media-breakpoint-down(sm) { 
    width: 100%;
   }
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 42px;
  display: inline-block;
  // font-family: "CircularXX TT";
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

/* a:hover,
a:active, */
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.submitBtn {
  display: flex;
  position: relative;
  text-align: center;
  button{
    font-size: 0.8rem;
    text-align: center;
  }
  img {
    vertical-align: middle;
  }
  @include media-breakpoint-down(sm) { 
    width: 100%;
   }
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
  /* Firefox */
}

.fhb-red {
  color: #a1252c;
}

.ll-textfield-error {
  background-color: $redFaint;
  border: 1px solid $red;
  box-shadow: inset 0px 1px 3px 1px rgba(204, 75, 55, 0.3);
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  height: 42px;
  width: 100%;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  padding-left: 14px;
  padding-right: 14px;
}

.emptySpace {
  position: relative;
  height: 30px;
}

.phone-number-label {
  margin-top: 10px;
}

.buttons {
  display: flex;
  position: relative;

  // flex-direction: row;
  // justify-content: space-between;
}

.backBtn {
  display: flex;
  // border: 1px solid red;
  background-color: #FFFFFF;
  text-decoration: underline;
  margin-top: 55px;
  height: 30px;
  width: 50px;
  font-size: 18px;
  font-weight: 450;
  margin-right: 18%;
  cursor: pointer;
  border-radius: 0
}

.backBtn:hover {
  background-color: #ffffff;
  color: #811E24;
}
</style>
