export default {
  namespaced: true,

  state: {
    loading: false,
    sentOTPMethod: '',
    methodsStatus: '',
    accInfo: [],
    token: '',
    resendInfo: '',
    mfaErrCounter: 0,
    finalEmailPayload: '',
    finalPhonePayload: ''
  },

  getters: {
    loading: (state) => state.loading,
    sentOTPMethod: (state) => state.sentOTPMethod,
    methodsStatus: (state) => state.methodsStatus,
    accInfo: (state) => state.accInfo,
    token: (state) => state.token,
    resendInfo: (state) => state.resendInfo,
    mfaErrCounter: (state) => state.mfaErrCounter,
    finalEmailPayload: (state) => state.finalEmailPayload,
    finalPhonePayload: (state) => state.finalPhonePayload
  },

  actions: {
    updateLoading ({ commit }, value) {
      commit('setLoading', value)
    },
    updateSentOTPMethod ({ commit }, value) {
      commit('setSentOTPMethod', value)
    },
    updateMethodsStatus ({ commit }, value) {
      commit('setMethodsStatus', value)
    },
    updateAccInfo ({ commit }, value) {
      commit('setAccInfo', value)
    },
    updateToken ({ commit }, value) {
      commit('setToken', value)
    },
    updateResendInfo ({ commit }, value) {
      commit('setResendInfo', value)
    },
    updateMfaErrCounter ({ commit }, value) {
      commit('setMfaErrCounter', value)
    },
    updateEmailPayload ({ commit }, value) {
      commit('setEmailPayload', value)
    },
    updatePhonePayload ({ commit }, value) {
      commit('setPhonePayload', value)
    },
  },

  mutations: {
    setLoading: (state, value) => (state.loading = value),
    setSentOTPMethod: (state, sentOTPMethod) =>
      (state.sentOTPMethod = sentOTPMethod),
    setMethodsStatus: (state, value) => (state.methodsStatus = value),
    setAccInfo: (state, value) => (state.accInfo = value),
    setToken: (state, value) => (state.token = value),
    setResendInfo: (state, value) => (state.resendInfo = value),
    setMfaErrCounter: (state, value) => (state.mfaErrCounter = value),
    setEmailPayload: (state, value) => (state.finalEmailPayload = value),
    setPhonePayload: (state, value) => (state.finalPhonePayload = value),
  }
}
