import axios from "axios";

const ENROLLMENTS_BASE_URL = process.env.VUE_APP_API_URL;

export default {
  /* proxy call that sends response token for validation */
  verifyResponseToken(payload) {
    const endpoint = ENROLLMENTS_BASE_URL + "/recaptcha";

    return axios
      .post(endpoint, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  },
};
