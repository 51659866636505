import store from '@/store'

const testRoute = {
  getToken () {
    return store.getters['app/jwt']
  },
  loadRoutes(routes) {
    routes.push(
      {
        path: '/test/seed',
        name: 'Seed',
        beforeEnter: (to, from, next) => {
          store.commit('app/setJWT', to.query.jwt)
          store.commit('enroll/setID', to.query.id)
          store.commit('enroll/setPhone', to.query.phone)
          store.commit('enroll/setEmail', to.query.email)
          console.log(this.getToken())
          next({
            path: (!to.query.redirect) ? '/' : to.query.redirect
          })
        }
      }
    )
    return routes
  }
}

export default testRoute
