<template>
  <div id="account-exists">
    <the-header></the-header>
    <div class="bg-container">
      <div class="bg-orange">
        <h1 class="bg-text">Enroll in Online Banking</h1>
        <div class="svg-container">
          <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
            class="coh-inline-element">
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>

    <div class="form-container smaller-header" id="account-exists-page">
      <form id="main">
        <div class="headImgContainer">
          <img srcset="@/assets/images/login.png 1x, @/assets/images/login-2x.png 2x" src="@/assets/images/login-2x.png"
            alt="computer with browser and login fields illustration" />
        </div>
        <div class="bg-text1">
          <h3 class="bg-text-line">You already have an online account.</h3>
          <p class="bg-text-line1">
            Please log in or select from the options below
          </p>
        </div>

        <div class="submitBtn">
          <button type="button" id="account-exists-login-btn" data-test="acct-exists-login-btn"
            @click="toLogin">Login</button>
        </div>

        <div class="bottomLinksContainer">
          <a :href="fhbOnline + '/resetPasswordUsername'"
            id="account-exists-frgt-password-link" data-test="acct-exists-pw-link">Forgot Password</a>
          <a href="/forgotusername" id="account-exists-frgt-username-link" data-test="acct-exists-un-link">Forgot
            Username</a>
        </div>
        <div class="bottomText">
          <p class="bottomTextLine">For assistance, contact us at (808) 643-4343 or toll-free at (888) 643-4343, daily
            7am-7pm HST.</p>
        </div>
      </form>
    </div>

    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
export default {
  name: 'visitBranch',
  components: { TheHeader, TheFooter },
  data() {
    return {
      fhbOnline: process.env.VUE_APP_FHB_ONLINE_URL
    }
  },
  methods: {
    toLogin() {
      location.href = this.fhbOnline
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

img {
  padding-top: 30px;
  padding-bottom: 15px;
  height: auto;
  width: 93.5px;
}

.bg-text-line {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}

.bg-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-left: 30px;
}

.bg-text-line1 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 15.5px;
  // padding-left: 20px;
}

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 40px;
  display: inline-block;
  //font-family: "CircularXX TT";
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

/* a:hover,
  a:active, */
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.submitBtn {
  position: relative;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 10px;
}

.bottomLinksContainer {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.bottomText {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 25px;
}

.bottomTextLine {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
