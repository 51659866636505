export default {
  namespaced: true,
  state: {
    user: {
      accountNum: "",
      email: "",
      loginID: "",
    },
  },
  actions: {
    setUserAccountNum({ commit }, accountNum) {
      commit("setUserAccountNum", accountNum);
    },
    setUserEmail({ commit }, email) {
      commit("setUserEmail", email);
    },
    setUserLoginID({ commit }, ID) {
      commit("setUserLoginID", ID);
    },
  },
  mutations: {
    setUserAccountNum(state, value) {
      state.user.accountNum = value;
    },
    setUserEmail(state, value) {
      state.user.email = value;
    },
    setUserLoginID(state, value) {
      state.user.loginID = value;
    },
  },
  getters: {
    userAccountNum: (state) => state.user.accountNum,
    userEmail: (state) => state.user.email,
    userLoginID: (state) => state.user.loginID,
  },
};
