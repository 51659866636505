<!-- Path: /enrollments/sso/:id -->
<template>
    <div id="funding-enrollment-page">
    <the-header />
    <HomepageBg></HomepageBg>
      <!-- API error (for SSO on created)-->
      <div v-if="this.api_err" class="api-err-container">
        <h3>Something Went Wrong</h3>
        <p>{{ this.api_err }}</p>
        <!-- <button @click="closeApiErr">Close</button> -->
      </div>
      <!-- waiting for token/enrollment_id -->
      <div v-if="!this.token && !this.api_err" class="loader-container">
        <img class="loader" srcset="@/assets/images/fhb-logo-load-reverse.gif 1x, @/assets/images/fhb-logo-load-reverse.gif 2x" src="@/assets/images/fhb-logo-load-reverse.gif" title="loader"/> 
      </div>
      <!-- Wait for token to load form -->
      <div class="form-container" id="main" v-else-if="this.token && this.enrollment_id && !this.api_err">
        <form @submit.prevent="submitEnrollment">
          <div class="recommend-container">
            <div id="first-line-text">
              For your security we strongly recommend:
            </div>
            <div class="list-container">
              <ul>
                <li>
                  Create a unique username and password that is complex, but that you can easily remember. Do not use your name as your User ID
                </li>
                <li>
                  Do not use the same Username or password that you use for any other websites
                </li>
                <li>
                  Keep First Hawaiian Bank up to date with your contact information
                </li>
              </ul>
            </div>
          </div>
          <div class="v-spacer-20"/>
          <!-- Username -->
          <div class="login-input-container">
            <div class="input-container">
              <label for="enrollment_user-id" class="tf-label">
                Username <span class="fhb-red">*</span>
              </label>
              <input type="text" id="enrollment_user_id" name="enrollment_user_id_name" 
                test="enrollment_user_id_test" ariaLabel="enrollment_user_id"  
                v-model="username" :class="missingUsername ? 'll-textfield-error' : 'll-textfield'" 
                @blur="validateUsername(false)" :disabled="submitLoader"
                />
                <div>
                  Must be 6-32 characters long. Alphanumeric characters and these special characters are allowed: ~!@$%^*-_+ (no spaces).
                </div>
                <!-- error container -->
                <div class="v-spacer-20">
                  <div v-if="missingUsername" class="error-container">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                    <div>Please enter a username</div>
                  </div>
                  <div v-else-if="invalidUsername" class="error-container">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                    <div>Please enter a valid username</div>
                  </div>
                  <div v-else-if="isUserNameAvailable == false" class="error-container">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                    <div>This username is not available</div>
                  </div>
                  <div v-else-if="isUserNameAvailable == true" class="success-container">
                    <img srcset="@/assets/images/green_check.png 1x, @/assets/images/green_check_2x.png 2x" src="@/assets/images/green_check.png" title="success" />
                    <div>This username is available</div>
                  </div>
                  <div v-else-if="checkUsernameErr" class="error-container">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                    <div>Error has occured when checking availability. Please try again</div>
                  </div>
                </div>
            </div>
          </div>
          <!-- Password -->
          <div class="login-input-container">
            <div class="input-container">
              <label for="password_id" class="tf-label">
                Password <span class="fhb-red">*</span>
              </label>
              <input type="password" id="password_id" name="password_name" 
                test="password_test" ariaLabel="password"
                @input="validatePassword" v-model="password"
                :disabled="submitLoader"
                :class="missingPassword ? 'll-textfield-error' : 'll-textfield'" autocomplete="current-password"/>
                <!-- error container -->
                <div>
                  <div :class="password_valid ? 'pw-valid-container' : 'pw-error-container'">
                    <table>
                      <!-- char range -->
                      <tr>
                        <td>
                          <img v-if="inRange" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                          <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                        </td>
                        <td class="err-text-col">
                          Must be between 9 and 15 characters
                        </td>
                      </tr>
                      <!-- contains numeric -->
                      <tr>
                        <td>
                          <img v-if="containsNumeric" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                          <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>

                        </td>
                        <td class="err-text-col">
                          Must contain at least 1 number
                        </td>
                      </tr>
                      <!-- contain lower char -->
                      <tr>
                        <td>
                          <img v-if="containsLower" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                          <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                        </td>
                        <td class="err-text-col">
                          Password must contain a minimum of 1 lower case character
                        </td>
                      </tr>
                      <!-- contain upper char -->
                      <tr>
                        <td>
                          <img v-if="containsUpper" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                          <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                        </td>
                        <td class="err-text-col">
                          Pasword must contain a minimum of 1 upper case character
                        </td>
                      </tr>
                      <!-- contain special char -->
                      <tr>
                        <td>
                          <img v-if="containsSpecial" class="img-col" srcset="@/assets/images/checkmark-green-circle.png 1x, @/assets/images/checkmark-green-circle-2x.png 2x" src="@/assets/images/checkmark-green-circle.png" title="error"/>
                          <img v-else class="img-col" srcset="@/assets/images/checkmark-red-circle.png 1x, @/assets/images/checkmark-red-circle-2x.png 2x" src="@/assets/images/checkmark-red-circle.png" title="error"/>
                        </td>
                        <td class="err-text-col">
                          Pasword must contain a minimum of 1 special character (no spaces)
                        </td>
                      </tr>

                    </table>
                  </div>
                </div>
            </div> 
            
          </div>
            <!-- Confirm Password -->
            <div class="login-input-container">
            <div class="input-container">
              <label for="confirm_password_id" class="tf-label">
                Confirm Password <span class="fhb-red">*</span>
              </label>
              <input type="password" id="confirm_password_id" name="confirm_password_name" 
                test="confirm_password_test" ariaLabel="confirm_password" 
                :class="missingConfirmPW ? 'll-textfield-error' :'ll-textfield'" autocomplete="current-password"
                @blur="validateConfirmPassword" v-model="confirm_password"
                :disabled="submitLoader"
                />
                <!-- error conatiner -->
                <div class="v-spacer-40">
                  <div v-if="missingConfirmPW" class="error-container">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                    <div>Please confirm your password</div>
                  </div>
                  <div v-else-if="invalidConfirmPW" class="error-container">
                    <img srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x" src="@/assets/images/exclaimation-lg.png" title="error"/>
                    <div>Your passwords don't match</div>
                  </div>
                </div>
            </div> 
          </div>
          <!-- error for submit -->
          <div class="error-container">
            <div v-if="post_api_err" class="post-api-err-container">
              {{ post_api_err }}
            </div>
          </div>
          <div class="v-spacer-20"/>
          <div class="button-container" >
            <button :class="submitLoader ? 'processing-button' : 'enroll-button' " type="submit" :disabled="submitLoader">
              {{ submitBtnText }}
            </button>
          </div>
          <div class="v-spacer-20"/>
        </form>
      </div>
      <the-footer></the-footer>
      <emailFailedModal v-if="emailFailedModal" @continue="continueToLogin" :errMsg="post_api_err" errHeader="Enrollment Successful"/>
    </div>
  </template>
<script>
import { mapGetters, mapActions } from 'vuex'
import TheHeader from "@/components/header";
import TheFooter from "@/components/footer";
import HomepageBg from "@/components/homepagebg";
import jQuery from 'jquery';
import retailApi from '@/app/retail-api';
import emailFailedModal from '@/components/Modals/EmailFailedModal.vue'
import RetailApi from '@/app/retail-api'

  export default {
    name: 'EnrollmentPage',
    components: {
        TheHeader,
        TheFooter,
        HomepageBg,
        emailFailedModal
    },
    data() {
      return {
        password: '',
        confirm_password: '',
        processing: false,
        next: false,
  
        missingUsername: false,
        invalidUsername: false,
        missingPassword: false,
        invalidPassword: false,
        missingConfirmPW: false,
        invalidConfirmPW: false,
        inRange: false,
        containsNumeric: false,
        containsLower: false,
        containsUpper: false,
        containsSpecial: false,
        password_valid: false,
        isUserNameAvailable: null,
        checkUsernameErr: false,

        api_err: '',
        post_api_err:'',
        emailFailedModal: false,
        submitBtnText: 'Enroll',
        submitLoader: false,
      }
    },
    async created() {
      this.api_err = ''
      this.sso_id = this.$route.params.id
      if (this.sso_id) {
        const resp = await retailApi.getFundingSSOToken(this.sso_id)
        if (resp) {
          switch (resp.status) {
            case 200:
              this.token = resp.data?.response?.token
              this.enrollment_id = resp.data?.response?.enrollment_id
              if (this.token == '' || this.enrollment_id == '') {
                this.api_err = 'Something went wrong on our side. Please try again.'
              }
              break
            case 404:
            case 422:
              this.api_err = 'Expired or Invalid URL'
              break
            case 500:
              this.api_err = 'System error occurred. Please try again'
              break
            default:
              // unexpected err
              this.api_err = 'Something unexpected happened. Please try again'
          }
        } else {
          this.api_err = 'Something unexpected happened. Please try again'
        }
      } else {
        // error no sso_id
      }
    },
    mounted() {
        jQuery('html, body').animate({ scrollTop: 0 })
    },
    methods: {
      ...mapActions('fundnow', ['setUsername', 'setSSOID', 'setToken', 'setEnrollmentID']),
      togglePasswordVisible() {
        var x = document.getElementById("password");
        x.type = x.type == 'password' ? 'text' : 'password'
      },
      // submit method
      submitEnrollment() {
        this.validatePage()
      },
      // validate all fields 
      validatePage() {
        this.validateUsername(true)
        this.validatePassword()
        this.validateConfirmPassword()
        if (
          this.invalidUsername ||
          this.missingUsername ||
          this.invalidPassword ||
          this.missingPassword ||
          this.invalidConfirmPW ||
          this.missingConfirmPW ||
          !this.isUserNameAvailable ||
          this.checkUsernameErr
        ) {
          console.log('fail')
        } else {
          this.postEnrollment()
        }
      },
      continueToLogin() {
        this.EmailFailedModal = false
        this.$router.push('/funding-login')
      },
      // post API call for form. On success push for user to login.
      async postEnrollment(){
        this.post_api_err = ''
        this.submitBtnText = 'Processing...'
        this.submitLoader = true
        // Re-validate username
        await this.checkUsername()
        // If the username is no longer available:
        if (!this.isUserNameAvailable || this.checkUsernameErr) {
          this.submitBtnText = 'Enroll'
          this.submitLoader = false
        }

        const resp = await retailApi.postFundingSSOEnroll(this.enrollment_id, this.username, this.password, this.token)
        this.submitLoader = false
        if (resp) {
          switch (resp.status) {
            case 200:
              if (resp.data?.message && resp.data.message != 'OK') {
                // couldn't save/send email
                this.post_api_err = 'Enrollment was successful, but we failed to send your confirmation email'
                this.emailFailedModal = true
              } else {
                // success
                this.$router.push('/funding-login')
              }
              break
            case 400:
              // apigee.enroll error
              this.post_api_err = 'Your Enrollment could not be completed. Please try again'
              break
            case 403:
              this.post_api_err = 'You are already enrolled in Online Banking'
              break
            case 404:
              this.post_api_err = 'Your enrollment record could not be found id: ' + this.enrollment_id
              break
            case 422:
              // bad username, password, or groupID(BE)
              this.post_api_err = 'Your enrollment could not be completed. Please try again with a new username/password'
              break
            case 500:
              this.post_api_err = 'We encountered a system error. Please try again.'
              break
            default:
              this.post_api_err = 'Something unexpected happened. Please try agin'
              // unexpected err
          }
        } else {
          // no resp
          this.api_err = 'Something unexpected happened. Please try agin'
        }
        this.submitBtnText = 'Enroll'
      },
      // Validate Username
      validateUsername(skipCall) {
        var user = document.getElementsByName('enrollment_user_id_name')[0]
        this.missingUsername = this.username ? false : true
        if (!this.missingUsername) {
          // Make sure username is within 6-32 chars and doesn't contain certain special chars
          const regex = /^([a-zA-Z0-9~!@$%^*\-_+]{6,32})$/
          if ((this.username.length >= 6 && this.username.length <= 32) && regex.test(this.username)) {
            this.invalidUsername = false
            user.setAttribute("aria-invalid", "false")
            if(!skipCall) {
              // Validate username exists
              this.checkUsername()
            }
          } else {
            this.invalidUsername = true
            user.setAttribute("aria-invalid", "true")
          }
        } else {
          this.password_valid = false
          user.setAttribute("aria-invalid", "true")
        }
      },
      // Validate password
      validatePassword() {
        var pass = document.getElementsByName('password_name')[0]
        this.missingPassword = this.password ? false : true
        if (!this.missingPassword) {
          // Make sure password is in range and contains at least one numberic, one lowercase and one uppercase
          this.inRange = this.checkRange()
          this.containsNumeric = this.checkNumeric()
          this.containsLower = this.checkLower()
          this.containsUpper = this.checkUpper()
          this.containsSpecial = this.checkSpecial()
          if (this.inRange && this.containsNumeric && this.containsLower && this.containsUpper && this.containsSpecial) {
            this.password_valid = true
            this.invalidPassword = false
          }
          else {
            this.password_valid = false
            this.invalidPassword = true
          }
  
          pass.setAttribute("aria-invalid", "false")
        } else {
          this.password_valid = false
          this.invalidPassword = true
          this.inRange = false
          this.containsNumeric = false
          this.containsLower = false
          this.containsUpper = false
          this.containsSpecial = false
          pass.setAttribute("aria-invalid", "true")
        }
      },
      checkSpecial() {
        return this.password != "" && !(this.password.match(/^[a-zA-Z0-9\s]+$/) !== null) && !this.password.includes(" ")
      },
      checkRange() {
        if (this.password.length >= 9 && this.password.length <= 15) {
          return true
        }
        return false
      },
      checkNumeric() {
        const numeric = /\d/
        if (numeric.test(this.password)) {
          return true
        }
        return false
      },
      checkLower() {
        const lowercase = /[a-z]/g
        if (lowercase.test(this.password)) {
          console.log('testing')
          return true
        }
        return false
      },
      checkUpper(){
      const uppercase = /[A-Z]/g
      if (uppercase.test(this.password)) {
          return true
        }
        return false
      },
      // Validate confirm password if it matches with other password
      validateConfirmPassword() {
        var confirm_pw = document.getElementsByName('confirm_password_name')[0]
        this.missingConfirmPW = this.confirm_password ? false : true
        if (!this.missingConfirmPW) {
          if (this.confirm_password == this.password) {
            this.invalidConfirmPW = false
            confirm_pw.setAttribute("aria-invalid", "false")
          } else {
            this.invalidConfirmPW = true
            confirm_pw.setAttribute("aria-invalid", "true")
          }
        } else {
          confirm_pw.setAttribute("aria-invalid", "true")
        }
      },
      closeApiErr() {
        this.api_err = ''
      },
      async checkUsername() {
        this.showLoader = true
        this.isUserNameAvailable = null
        let encodedUserID = encodeURIComponent(this.username)
        var user = document.getElementsByName('enrollment_user_id_name')[0]
        const resp = await RetailApi.checkUserName(encodedUserID, this.enrollment_id, this.token)
        if (resp && resp.status == 200) {
          if (resp && resp.message != 'canceled') {
            if (resp.data.response.username_available === false) {
              this.isUserNameAvailable = false
              this.checkUsernameErr = false
              user.setAttribute("aria-invalid", "true")
            } else if (resp.data.response.username_available === true) {
              this.isUserNameAvailable = true
              this.checkUsernameErr = false
              user.setAttribute("aria-invalid", "false")
            }
          }
        } else if (resp && resp.status !== 200) {
          this.showLoader = false
          this.checkUsernameErr = true
          user.setAttribute("aria-invalid", "true")
        }
      },
    },
    computed: {
      ...mapGetters('fundnow', ['getCustomerData', 'getUsername', 'getSSOID', 'getToken', 'getEnrollmentID']),
      username: {
        get() { return this.getUsername },
        set(v) { this.setUsername(v) }
      },
      sso_id: {
        get() { return this.getSSOID },
        set(v) { this.setSSOID(v)}
      },
      enrollment_id: {
        get() { return this.getEnrollmentID },
        set(v) { this.setEnrollmentID(v)}
      },
      token: {
        get() { return this.getToken },
        set(v) { this.setToken(v)}
      }
    }
  }
  </script>
  <style scoped lang="scss">
  @import "./src/scss/_variables.scss";
  @import "./src/scss/_mixins.scss";
  
  .form-content-container {
    margin-left: 20px;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .section-2 {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
  }
  input[type=radio] {
    width: 25px;
    height: 25px;
    margin: 0 8px;
  } 
  
  .radio-line {
    margin: 30px 15px; 
    input, label, div {
      display: inline-block;
      justify-self: center;
      vertical-align: middle;
    }
  }
  .login-button, .enroll-button {
    background-color: $yellowLight;
    width: 274px;
    height: 40px;
    border: none;
    border-radius: 999px;
    padding: 4px, 24px, 4px, 24px;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    &:hover, :active { 
      cursor: pointer;
      background-color: $yellowHover;
    }
  }
  .processing-button {
    background-color: $grayLight;
    width: 274px;
    height: 40px;
    border: none;
    border-radius: 999px;
    padding: 4px, 24px, 4px, 24px;
    text-transform: uppercase;
    font-size: 13px;
  }
  
  .back-button {
    background-color: $white;
    width: 60px;
    height: 40px;
    margin-right: 40px;
    border: 1px solid black;
    border-radius: 999px;
    padding: 4px, 24px, 4px, 24px;
    text-align: center;
    cursor: pointer;
    &:hover, :active {
      cursor: pointer;
      background-color: $grayVlight;
    }
    img {
      vertical-align: middle;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    margin-top: 0px;
  }
  .error {
    color: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 15px;
    font-weight: 450;
    line-height: 23px;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  
  .login-input-container {
    padding: 10px 0px;
    label {
      font-weight: bold;
    }
  }
  
  .login-info {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .recovery-info {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .from-retail {
    font-size: 24px;
    text-align: center;
  }
  .from-retail-header {
    font-size: 28.8px;
  }
  .img-container {
    text-align: center;
    padding: 30px 0px;
  }
  .app-text {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    .mobile-apps {
      padding-top: 20px;
    }
  }
  .appleLogo {
    padding-right: 20px;
    height: 40px;
    width: 134px;
  }
  .playstoreLogo {
    height: 40px;
    width: 134px;
  }
  .recommend-container {
    padding: 20px;
    background-color: $orangeFaint;
    text-align: left;
    #first-line-text {
      padding-left: 12px;
    }
  }
  .ll-textfield {
    max-width: 650px;
  }
  .input-container {
   margin: 0px 20px;
  }
  .list-container {
    li {
      margin: 5px 0px;
    }
  }
  .error-container {
    display: flex;
    color: $red;
    img {
      vertical-align: middle;
      width: 15px;
      height: 15px;
    }
    align-items: center;
    gap: 3px;
  }
  .pw-error-container {
    color: $red;
    height: max-content;
    background-color: $redLight;
    margin: 10px 0px;
    margin-top: 20px;
    border-left: solid 10px $red; 
    padding: 15px;
    table {
      td {
        padding: 5px 2px;
      }
    }
  }
  .img-col {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
  .err-text-col {
    color: $black;
  }
  .pw-valid-container {
    height: max-content;
    background-color: $greenLight;
    margin: 10px 0px;
    margin-top: 20px;
    border-left: solid 10px $green; 
    padding: 15px;
    table {
      td {
        padding: 5px 2px;
      }
    }
  }
  .loader {
  width: 150px;
  height: 150px;
  text-align: center;
  vertical-align: middle;
}
.loader-container {
  text-align: center;
  vertical-align: middle;
}
.api-err-container {
  p {
    color: $red;
    font-size: 18px;
    font-weight: 500;
  }
  h3 {
    font-size: 25px;
    margin-top: 1em;
    font-weight: 700;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: auto;
  line-height: 23px;
  border-radius: 3px;
  border: solid 2px $red;
  width: 750px;
  margin:  1em auto;
  button {
    color: #333333;
    background: #fec443;
    border: 1px solid white;
    border-radius: 2px;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.029em;
    border-radius: 28px;
    height: 40px;
    width: 150px;
    margin: 1em 0;
  }
  button:hover,
  button:active {
    background-color: #fed67b;
    border-color: white;
  }
}
.post-api-err-container {
  color: $red;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: auto;
  max-height: 60px;
  border-radius: 3px;
  border: solid 2px $red;
  max-width: 500px;
  width: 100%;
  margin:  1em auto;
}
.error-container {
  height: 60px;
}
.success-container {
  height: 60px;
  display: flex;
  color: $green;
  img {
    vertical-align: middle;
    width: 15px;
    height: 15px;
  }
  align-items: center;
  gap: 3px;
}
</style>
  