
export default {
  namespaced: true,
  state: {
    applicant: {
      email: '',
      phoneNumber: ''
    }
  },
  actions: {
    setApplicantEmail ({ commit }, email) {
      commit('setApplicantEmail', email)
    },
    setPhoneNum ({ commit }, phoneNum) {
      commit('setPhoneNum', phoneNum)
    }
  },
  mutations: {
    setApplicantEmail (state, value) {
      state.applicant.email = value
    },
    setPhoneNum (state, value) {
      state.applicant.phoneNumber = value
    }
  },
  getters: {
    applicantSubmitEmail: (state) => state.applicant.email,
    applicantPhoneNum: (state) => state.applicant.phoneNumber
  }
}
