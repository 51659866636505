export default {
  USStates: [
    { key: 'HI', value: 'HI' },
    { key: 'GU', value: 'GU' },
    { key: 'MP', value: 'MP' }
  ],
  HawaiiZipcodes: [
    96747, 96812, 96703, 96759, 96854, 96746, 96732, 96785, 96827, 96858, 96739, 96825, 96708, 96850,
    96810, 96815, 96821, 96719, 96722, 96790, 96742, 96861, 96766, 96765, 96857, 96778, 96761, 96777,
    96786, 96760, 96860, 96709, 96820, 96772, 96720, 96730, 96781, 96726, 96710, 96830, 96728, 96769,
    96731, 96715, 96835, 96795, 96809, 96734, 96848, 96738, 96802, 96803, 96714, 96743, 96712, 96721,
    96727, 96838, 96849, 96792, 96823, 96797, 96725, 96750, 96817, 96749, 96745, 96784, 96822, 96757,
    96828, 96841, 96863, 96801, 96770, 96780, 96764, 96844, 96763, 96737, 96767, 96717, 96779, 96840,
    96762, 96733, 96704, 96808, 96819, 96816, 96741, 96748, 96706, 96783, 96753, 96807, 96788, 96776,
    96836, 96847, 96716, 96782, 96804, 96713, 96826, 96793, 96839, 96824, 96751, 96859, 96755, 96754,
    96806, 96718, 96705, 96843, 96729, 96789, 96773, 96814, 96837, 96818, 96771, 96707, 96853, 96791,
    96740, 96796, 96846, 96813, 96811, 96744, 96752, 96805, 96756, 96774, 96768, 96701, 96898
  ],
  GuamZipcodes: [
    96910, 96912, 96913, 96915, 96916, 96917, 96919, 96921, 96923, 96928, 96929, 96931, 96932
  ],
  SaipanZipcodes: [
    96950
  ],
  CNMIZipcodes: [
    96951, 96952
  ],
  AreaCodes: [
    201, 202, 203, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216, 217, 218, 219, 220, 223, 224, 225, 228, 229, 231,
    234, 239, 240, 248, 251, 252, 253, 254, 256, 260, 262, 267, 269, 270, 272, 276, 278, 281, 283,
    301, 302, 303, 304, 305, 307, 308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319, 320, 321, 323, 325, 330, 331, 332,
    334, 336, 337, 339, 340, 341, 346, 347, 351, 352, 360, 361, 369, 380, 385, 386,
    401, 402, 404, 405, 406, 407, 408, 409, 410, 412, 413, 414, 415, 417, 419, 423, 424, 425, 430, 432, 434, 435, 440, 442,
    443, 458, 464, 469, 470, 475, 478, 479, 480, 484,
    501, 502, 503, 504, 505, 507, 508, 509, 510, 512, 513, 515, 516, 517, 518, 520, 530, 539, 540, 541, 551, 557, 559, 561,
    562, 563, 564, 567, 570, 571, 573, 574, 575, 580, 585, 586,
    601, 602, 603, 605, 606, 607, 608, 609, 610, 612, 614, 615, 616, 617, 618, 619, 620, 623, 626, 627, 628, 629, 630, 631,
    636, 641, 646, 650, 651, 657, 660, 661, 662, 667, 669, 670, 671, 678, 679, 681, 682, 684, 689,
    701, 702, 703, 704, 706, 707, 708, 712, 713, 714, 715, 716, 717, 718, 719, 720, 724, 725, 727, 731, 732, 734, 737, 740,
    743, 747, 754, 757, 760, 762, 763, 764, 765, 769, 770, 772, 773, 774, 775, 779, 781, 782, 784, 785, 786, 787,
    801, 802, 803, 804, 805, 806, 808, 810, 812, 813, 814, 815, 816, 817, 818, 819, 825, 828, 830, 831, 832, 835, 843, 845,
    847, 848, 850, 856, 857, 858, 859, 860, 862, 863, 864, 865, 870, 872, 873, 878,
    901, 903, 904, 906, 907, 908, 909, 910, 912, 913, 914, 915, 916, 917, 918, 919, 920, 925, 927, 928, 929, 931, 935, 936,
    937, 939, 940, 941, 947, 949, 951, 952, 954, 956, 957, 959, 970, 971, 972, 973, 975, 978, 979, 980, 984, 985, 989
  ],
  HawaiiBranches: [
    'Aina Haina Banking Center',
    'Ewa Beach Branch',
    'Haleiwa Branch',
    'Hawaii Kai Branch',
    'Hilo Branch',
    'Kahala Banking Center',
    'Kahana Branch',
    'Kahuku Branch',
    'Kahului Banking Center',
    'Kailua Banking Center',
    'Kaimuki Branch',
    'Kalihi Banking Center',
    'Kamuela Branch',
    'Kaneohe Bay Branch',
    'Kaneohe Branch',
    'Kapaa Branch',
    'Kapahulu Branch',
    'Kapiolani Banking Center',
    'Kapolei Branch',
    'Kealakekua Branch',
    'Kihei Branch',
    'Koloa Branch',
    'Kona Banking Center',
    'Lahaina Branch',
    'Lanai Branch',
    'Lihue Banking Center',
    'Liliha Branch',
    'Main Banking Center',
    'Maite Banking Center',
    'Makiki Banking Center',
    'Manoa Branch',
    'Mililani Banking Center',
    'Moanalua Banking Center',
    'Pahoa Branch',
    'Pearl City Branch',
    'Pearlridge Banking Center',
    'Princeville Branch',
    'Pukalani Branch',
    'University Banking Center',
    'Wahiawa Branch',
    'Waiakea Banking Center',
    'Waianae Branch',
    'Waikiki Banking Center',
    'Waikoloa Branch',
    'Wailuku Branch',
    'Waimea Branch',
    'Waipahu Banking Center'
  ],
  GuamBranches: [
    'Dededo Branch',
    'Maite Banking Center',
    'Tamuning Branch'
  ],
  SaipanBranches: [
    'Gualo Rai Branch',
    'Oleai Branch'
  ],
  Branches: [
    'Aina Haina Banking Center',
    'Dededo Branch',
    'Ewa Beach Branch',
    'Gualo Rai Branch',
    'Haleiwa Branch',
    'Hawaii Kai Branch',
    'Hilo Branch',
    'Kahala Banking Center',
    'Kahana Branch',
    'Kahuku Branch',
    'Kahului Banking Center',
    'Kailua Banking Center',
    'Kaimuki Branch',
    'Kalihi Banking Center',
    'Kamuela Branch',
    'Kaneohe Bay Branch',
    'Kaneohe Branch',
    'Kapaa Branch',
    'Kapahulu Branch',
    'Kapiolani Banking Center',
    'Kapolei Branch',
    'Kealakekua Branch',
    'Kihei Branch',
    'Koloa Branch',
    'Kona Banking Center',
    'Lahaina Branch',
    'Lanai Branch',
    'Lihue Banking Center',
    'Liliha Branch',
    'Main Banking Center',
    'Maite Banking Center',
    'Makiki Banking Center',
    'Manoa Branch',
    'Mililani Banking Center',
    'Moanalua Banking Center',
    'Oleai Branch',
    'Pahoa Branch',
    'Pearl City Branch',
    'Pearlridge Banking Center',
    'Princeville Branch',
    'Pukalani Branch',
    'Tamuning Branch',
    'University Banking Center',
    'Wahiawa Branch',
    'Waiakea Banking Center',
    'Waianae Branch',
    'Waikiki Banking Center',
    'Waikoloa Branch',
    'Wailuku Branch',
    'Waimea Branch',
    'Waipahu Banking Center'
  ],
  Suffixes: [
    { key: 'JR', value: 'Junior' },
    { key: 'SR', value: 'Senior' },
    { key: 'II', value: 'The Second' },
    { key: 'III', value: 'The Third' },
    { key: 'IV', value: 'The Fourth' },
    { key: 'V', value: 'The Fifth' },
    { key: 'VI', value: 'The Sixth' },
    { key: 'VII', value: 'The Seventh' },
    { key: 'VIII', value: 'The Eight' }
  ],
  Countries: [
    'Afghanistan',
    'Aland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'British Virgin Islands',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'China, Hong Kong Special Administrative Region',
    'China, Macao Special Administrative Region',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo, Democratic Republic of the',
    'Congo, Republic of the',
    'Cook Islands',
    'Costa Rica',
    "Cote d'Ivoire",
    'Croatia',
    'Cuba',
    'Curacao',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor (Timor-Leste)',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands (Malvinas)',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guinea',
    'French Polynesia',
    'French Southen Territories',
    'Gabon',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea-Bissau',
    'Guinea',
    'Gutana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Honduras',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea, North',
    'Korea, South',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxemborg',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia, Federated States of',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar (Burma)',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'North Macedonia',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'OTHER',
    'Pakistan',
    'Palau',
    'Panama',
    'Papu New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russia',
    'Rwanda',
    'Saint Barthelemy',
    'Saint Helena',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Sark',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singaport',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'Spain',
    'Sri Lanka',
    'State of Palestine',
    'Sudan',
    'Sudan, South',
    'Suriname',
    'Svalbard and Jan Mayen Islands',
    'Sweden',
    'Switzerland',
    'Syria',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'The Bahamas',
    'The Gambia',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States Minor Outlying Islands',
    'United States Virgin Islands',
    'United States',
    'Uruguay',
    'Uzbekistan',
    'Vanatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Wallis and Futuna Islands',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
    'OTHER'
  ],
  States: [
    { key: 'AL', value: 'Alabama' },
    { key: 'AK', value: 'Alaska' },
    { key: 'AS', value: 'American Samoa' },
    { key: 'AZ', value: 'Arizona' },
    { key: 'AR', value: 'Arkansas' },
    { key: 'CA', value: 'California' },
    { key: 'CO', value: 'Colorado' },
    { key: 'CT', value: 'Connecticut' },
    { key: 'DE', value: 'Delaware' },
    { key: 'DC', value: 'District Of Columbia' },
    { key: 'FM', value: 'Federated States Of Micronesia' },
    { key: 'FL', value: 'Florida' },
    { key: 'GA', value: 'Georgia' },
    { key: 'GU', value: 'Guam' },
    { key: 'HI', value: 'Hawaii' },
    { key: 'ID', value: 'Idaho' },
    { key: 'IL', value: 'Illinois' },
    { key: 'IN', value: 'Indiana' },
    { key: 'IA', value: 'Iowa' },
    { key: 'KS', value: 'Kansas' },
    { key: 'KY', value: 'Kentucky' },
    { key: 'LA', value: 'Louisiana' },
    { key: 'ME', value: 'Maine' },
    { key: 'MH', value: 'Marshall Islands' },
    { key: 'MD', value: 'Maryland' },
    { key: 'MA', value: 'Massachusetts' },
    { key: 'MI', value: 'Michigan' },
    { key: 'MN', value: 'Minnesota' },
    { key: 'MS', value: 'Mississippi' },
    { key: 'MO', value: 'Missouri' },
    { key: 'MT', value: 'Montana' },
    { key: 'NE', value: 'Nebraska' },
    { key: 'NV', value: 'Nevada' },
    { key: 'NH', value: 'New Hampshire' },
    { key: 'NJ', value: 'New Jersey' },
    { key: 'NM', value: 'New Mexico' },
    { key: 'NY', value: 'New York' },
    { key: 'NC', value: 'North Carolina' },
    { key: 'ND', value: 'North Dakota' },
    { key: 'MP', value: 'Northern Mariana Islands' },
    { key: 'OH', value: 'Ohio' },
    { key: 'OK', value: 'Oklahoma' },
    { key: 'OR', value: 'Oregon' },
    { key: 'PW', value: 'Palau' },
    { key: 'PA', value: 'Pennsylvania' },
    { key: 'PR', value: 'Puerto Rico' },
    { key: 'RI', value: 'Rhode Island' },
    { key: 'SC', value: 'South Carolina' },
    { key: 'SD', value: 'South Dakota' },
    { key: 'TN', value: 'Tennessee' },
    { key: 'TX', value: 'Texas' },
    { key: 'UT', value: 'Utah' },
    { key: 'VT', value: 'Vermont' },
    { key: 'VI', value: 'Virgin Islands' },
    { key: 'VA', value: 'Virginia' },
    { key: 'WA', value: 'Washington' },
    { key: 'WV', value: 'West Virginia' },
    { key: 'WI', value: 'Wisconsin' },
    { key: 'WY', value: 'Wyoming' }
  ]
}
