<template>
  <div id="accountconfirmation">
    <the-header></the-header>
    <div class="bg-container">
      <div class="bg-orange">
        <h1 class="bg-text">Enroll in Online Banking</h1>
        <h2>Account Confirmation</h2>
        <div class="svg-container">
          <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
            class="coh-inline-element">
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>

    <div class="form-container" id="account-confirmation-page">
      <form id="main">
        <div class="headImgContainer">
          <img srcset="@/assets/images/login.png 1x, @/assets/images/login-2x.png 2x" src="@/assets/images/login-2x.png" alt="computer with browser and login fields illustration" />
        </div>
        <div class="bg-text1">
          <h3 class="bg-text-line">Congratulations!</h3>
          <p class="bg-text-line1">
            Your account has been created and you are now enrolled in FHB Online & Mobile Banking. Click the button below to return back to Funding.
          </p>
        </div>

        <div class="inforContainer">
          <div class="infoPadding">
            <label for="userName" id="userName" class="labelTitle">User Name</label>
            <div><span class="infoContext" aria-required="true" aria-labelledby="userName" data-test="user-name">{{ applicantUserID }}</span></div>
          </div>
          <div v-if="maskedAccNum && accInfo[0] != 0" class="infoPadding">
            <label for="feeAccount" id="feeAccount" class="labelTitle">Fee Account</label>
            <span  aria-required="true" aria-labelledby="fee-account" name="fee-account" class="infoContext" data-test="fee-account">{{ maskedAccNum ? maskedAccNum : "N/A" }}</span>
          </div>
          <div v-if="showEnrollType && accInfo[0] != 0" class="infoPadding">
            <label for="enrollType" id="enroll-type" class="labelTitle">Enrollment Type</label>
            <div><span  aria-required="true" aria-labelledby="enroll-type" name="enroll-type" class="infoContext" data-test="enroll-type">{{ showEnrollType ? showEnrollType : "N/A" }}</span></div>
          </div>

          <div v-if="(applicantEmail || applicantSubmitEmail)" class="infoPadding">
            <label for="email" id="email" class="labelTitle">Email</label>
            <div><span class="infoContext" aria-required="true" aria-labelledby="email" data-test="acct-confirm-email">{{ applicantEmail }}{{ maskedEmail }}</span></div>
          </div>
        </div>

        <div v-if="!mobileUser" class="submitBtn">
          <button type="button" id="account-confirmation-login-btn" data-test="acct-confirm-login-btn" @click="backToFunding">RETURN TO FUNDING </button>
        </div>
        <div v-if="!mobileUser" class="bg-text1">
          <p class="downloadContext">Download the FHB Mobile App</p>
        </div>
        
        <div v-if="!mobileUser" class="mobileStoreLogo">
          <a href="https://apps.apple.com/app/id1172432893" target="_blank"><img class="appleLogo" src="@/assets/images/Apple_Store_Badge@1.5x.png" alt="apple store badge"></a>
          <a href="https://play.google.com/store/apps/details?id=com.firsthawaiian.mobile" target="_blank"><img class="playstoreLogo" src="@/assets/images/google-play-badge@1.5x.png" alt="play store badge"></a>
        </div>

      </form>
    </div>

    <the-footer></the-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RetailApi from '@/app/retail-api'
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import jQuery from "jquery";

export default {
  name: 'OAOConfirmation',
  components: { TheHeader, TheFooter },
  created() {
    this.addServiceAccount(this.token, this.applicantID)
    if (this.applicantTypeOfAcc === 'typeAccountNumber') {
      this.typeofAcc = 'Account Number'
    }
    if (this.applicantTypeOfAcc === 'typeOfCreditCardAccount') {
      this.typeofAcc = 'Credit Number'
    }
    if (this.applicantPhoneNum) {
      const maskphone = this.applicantPhoneNum.replace('+1', '').replace("(", '').replace(")", '').replace('-', '').replaceAll(' ', '')
      let maskPhoneNum = "******" + maskphone.slice(6, 10)
      this.maskedPhone = maskPhoneNum
    }
    if (this.applicantSubmitEmail) {
      const FullEmail = this.applicantSubmitEmail
      const email = FullEmail.toUpperCase()
      let lastpartIndex = email.indexOf('@')
      let maskedEmail1 = email.slice(0, 3) + "******" + email.slice(lastpartIndex,)
      this.maskedEmail = maskedEmail1
    }
    if(this.applicantServiceChargeAcc){
      this.maskedAccNum = '******'+ this.applicantServiceChargeAcc.slice(6,10)
    }
    if(this.applicantTypeOfEnroll == 'Standard'){
      this.showEnrollType = 'Standard FHB Online Banking'
    }
    if(this.applicantTypeOfEnroll == 'Online'){
      this.showEnrollType = 'FHB Online with Bill Pay'
    }
    this.updateToken('')
  },
  data() {
    return {
      typeofAcc: '',
      maskedPhone: '',
      maskedEmail: '',
      maskedAccNum: '',
      showEnrollType: '',
      mobileUser: false
    }
    
  },
  computed: {
    ...mapGetters('enrollhome', ['applicantLastName', 'applicantPhone', 'applicantEmail', 'applicantTypeOfAcc', 'applicantConfirmAccNum', 'applicantCreditCardNum', 'applicantID']),
    ...mapGetters('profileInfo', ['applicantSubmitEmail', 'applicantPhoneNum']),
    ...mapGetters('createUserId', ['applicantUserID', 'applicantServiceChargeAcc', 'applicantTypeOfEnroll']),
    ...mapGetters('mfaData', ['token', 'accInfo']),

    disableToken: {
      get() {
        return this.token
      },
      set(value) {
        this.updateToken(value)
      }
    }
  },
  methods: {
    ...mapActions('mfaData', ['updateToken']),

    // go back to funding and pass un (maybe?)
    backToFunding() {
      // local 
      if (window.location.href.indexOf('localhost') > -1) {
        location.href = process.env.VUE_APP_FUNDING_STAGE +'existing-login?user=' + this.applicantUserID
      }
      else if (window.location.href.indexOf('staging.') > -1) {
        location.href = process.env.VUE_APP_FUNDING_STAGE + 'existing-login?user=' + this.applicantUserID
      }
      else {
        location.href = process.env.VUE_APP_FUNDING_PROD + 'existing-login?user=' + this.applicantUserID
      }
    },

    async addServiceAccount(token, id) {
      await RetailApi.addServiceAccount(token, id)
    }
  },
  mounted() {
    jQuery('html, body').animate({ scrollTop: 0 })
    
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobileUser = true
    }else{
      this.mobileUser = false
    }
  }

}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";
#account-confirmation-login-btn{
  font-size: 0.8rem;
}
.downloadContext{
  margin-bottom: 0;
}

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

img {
  padding-top: 30px;
  // padding-bottom: 15px;
  height: auto;
  width: 93.5px;
}

.bg-text {
  padding-top: 35px;
}

.bg-text-line {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}

.bg-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-left: 30px;
}

.bg-text-line1 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 20px;
  // padding-left: 20px;
}

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 40px;
  display: inline-block;
  //font-family: "CircularXX TT";
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

/* a:hover,
    a:active, */
  button:hover,
  button:active {
    background-color: #fed67b;
    border-color: white;
  }
  .submitBtn {
    position: relative;
    text-align: center;
    padding-bottom: 40px;
    padding-top: 10px;
  }
  .bottomLinksContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
  }
  .bottomText{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 25px;
  }
  .bottomTextLine{
      margin-top: 0px;
      margin-bottom: 0px;
  }
  .inforContainer{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
  }
  .labelTitle{
    display: inline-block;
    position: relative;
    font-weight: 700;
    font-size: 18px;
    width: 30%;
    margin-top: 0px;
    // float: left;
    text-align: right;
  }
  .infoContext{
    display: inline-block;
    white-space:normal;
    // position: relative;
    font-weight: 450;
    font-size: 18px;
    margin-left: 30px;
    
  }
  .infoPadding{
    display: flex;
    padding-bottom: 20px;
    white-space: normal;
  }
  .mobileStoreLogo{
    text-align: center;
  }
  .appleLogo{
    padding-right: 10px;
    height: 80px;
    width: 160px;
  }
  .playstoreLogo{
    height: 80px;
    width: 160px;
  }
  </style>
