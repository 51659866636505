<!-- Path: /funding-login -->
<template>
    <div class="page">
      <the-header />
      <HomepageBg></HomepageBg>
      <div class="form-container">  
        <form id="Q2OnlineLogin" name="Q2OnlineLogin" :action="this.loginURL" method="post">
            <div class="img-container">
            <img srcset="@/assets/images/user-account.png 1x, @/assets/images/user-account-2x.png 2x" src="@/assets/images/user-account.png" title="successful enrollment"/>
        </div>
        <div class="form-header from-retail-header">  
            Enrollment Successful
        </div>
        <div class="centered from-retail">
            You are now enrolled in FHB Online & Mobile Banking. Log in with the username and password you created to view your new accounts.
        </div>
            <div class="v-spacer-10"/>
            <!-- Username -->
            <div class="login-input-container">
            <div>
                <label for="user-id">
                Username <span class="fhb-red">*</span>
                </label>
                <input type="text" id="user_id" name="user_id" 
                  test="user_id" ariaLabel="user_id" test-data="login-username"
                  class="ll-textfield" v-model="username"
                  placeholder="Login ID" required/>
            </div>
            </div>
            <!-- Password -->
            <div class="login-input-container">
            <div>
                <label for="password">
                Password <span class="fhb-red">*</span>
                </label>
                <input type="password" id="password" name="password" 
                test="password" data-test="login-password" ariaLabel="password" placeholder="Password"
                class="ll-textfield" autocomplete="current-password"/>
                <div class="svg-container" @click="togglePasswordVisible">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6111 10.8439C23.5083 10.6854 21.9805 8.39904 19.2174 6.58091L23.4708 2.32646L21.9558 0.811523L1.09497 21.6735L2.6099 23.1884L8.12323 17.6751C9.29425 18.0404 10.5853 18.2686 11.9995 18.2686C19.2892 18.2686 23.4279 12.2849 23.6004 12.0289L24 11.4418L23.6111 10.8439ZM11.9984 16.1258C11.257 16.1258 10.5574 16.0423 9.88994 15.9073L11.9331 13.8642C12.203 13.9381 12.4848 13.9831 12.7795 13.9831C14.5537 13.9831 15.9936 12.5453 15.9936 10.7689C15.9936 10.4754 15.9486 10.1925 15.8747 9.92255L17.6542 8.14298C19.467 9.24865 20.7387 10.635 21.3655 11.4128C20.2009 12.7949 16.885 16.1258 11.9984 16.1258Z" fill="#7E7E7E"/>
                    <path d="M4.08756 15.6501L5.64428 14.0934C4.20756 13.1323 3.17689 12.0588 2.63156 11.4117C3.77793 9.98032 7.08207 6.48334 11.9997 6.48334C12.4047 6.48334 12.7947 6.51441 13.1772 6.56048L15.0156 4.72199C14.0793 4.48736 13.0786 4.34058 11.9997 4.34058C4.69718 4.34058 0.559505 10.5782 0.388084 10.8439L0.000244141 11.4417L0.399869 12.0288C0.492008 12.167 1.76909 14.0055 4.08756 15.6501Z" fill="#7E7E7E"/>
                </svg>
                </div>
            </div> 
            </div>
            <input style="visibility:hidden;" type="checkbox" id="forgotBox" name="forgot_password" >
            <div class="button-container">
            <button class="login-button" type="submit">
                Login
            </button>
            </div>

            <div class="app-text">
            <div >
                Download the app for FHB Online & Mobile Banking
            </div>
            <div class="mobile-apps">
                <a href="https://apps.apple.com/app/id1172432893" target="_blank"><img class="appleLogo" src="@/assets/images/Apple_Store_Badge@1.5x.png" alt="apple store badge"></a>
                <a href="https://play.google.com/store/apps/details?id=com.firsthawaiian.mobile" target="_blank"><img class="playstoreLogo" src="@/assets/images/google-play-badge@1.5x.png" alt="play store badge"></a>
            </div>
            </div>
            <div class="v-spacer-20"/>
        </form>
      </div>
      <the-footer></the-footer>
    </div>
  </template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
import TheHeader from "@/components/header";
import TheFooter from "@/components/footer";
import HomepageBg from "@/components/homepagebg";
import environments from '@/app/environments';
import jQuery from 'jquery';
  
  export default {
    name: 'LoginAfterEnrollment',
    props: {
      productName: String,
    },
    components: {
        TheFooter,
        TheHeader,
        HomepageBg
    },
    data() {
      return {
        processing: false,
        next: false,
      }
    },
    created() {
    },
    mounted() {
        jQuery('html, body').animate({ scrollTop: 0 })
    },
    methods: {
      ...mapActions('fundnow', ['setAccounts', 'setSelectedAccount', 'setUser']),
      togglePasswordVisible() {
        var x = document.getElementById("password");
        x.type = x.type == 'password' ? 'text' : 'password'
      },
    },
    computed: {
      ...mapGetters('fundnow', ['getAccounts', 'getUsername']),
      username: {
        get() { return this.getUsername },
        set(v) {
          this.setUsername(v)
        }
      },
      loginURL: {
        get() {
          return `${environments.enrolledLoginURL().replace("/login", "")}`
        }
      },  
    }
  }
  </script>
  <style scoped lang="scss">
  @import "./src/scss/_variables.scss";
  @import "./src/scss/_mixins.scss";
  
  .form-content-container {
    margin-left: 20px;
    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
  .section-2 {
    font-size: 18px;
    font-weight: 450;
    line-height: 27px;
    letter-spacing: -0.011em;
  }
  input[type=radio] {
    width: 25px;
    height: 25px;
    margin: 0 8px;
  } 
  
  .radio-line {
    margin: 30px 15px; 
    input, label, div {
      display: inline-block;
      justify-self: center;
      vertical-align: middle;
    }
  }
  .login-button, .enroll-button {
    background-color: $yellowLight;
    width: 274px;
    height: 40px;
    border: none;
    border-radius: 999px;
    padding: 4px, 24px, 4px, 24px;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    &:hover, :active { 
      cursor: pointer;
      background-color: $yellowHover;
    }
  }
  
  .enroll-button {
    background: none;
    border: 2px solid $grayDark;
    &:hover, :active { 
      background-color: $grayLight;
    }
  }
  
  .back-button {
    background-color: $white;
    width: 60px;
    height: 40px;
    margin-right: 40px;
    border: 1px solid black;
    border-radius: 999px;
    padding: 4px, 24px, 4px, 24px;
    text-align: center;
    cursor: pointer;
    &:hover, :active {
      cursor: pointer;
      background-color: $grayVlight;
    }
    img {
      vertical-align: middle;
    }
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    margin-top: 0px;
  }
  .error {
    color: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 15px;
    font-weight: 450;
    line-height: 23px;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
  }
  
  .login-input-container {
    div {
      width: 550px;
    }
    padding: 10px 20px;
    label {
      font-weight: bold;
    }
    display: flex;
    justify-content: center;
    .svg-container {
      width: 2em;
      height: 2em;
      position: relative;
      bottom: 2.3em;
      left: 465px;
      :hover {
        cursor: pointer;
      }
    }
  }
  
  .login-info {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .recovery-info {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .from-retail {
    font-size: 22px;
    text-align: center;
  }
  .from-retail-header {
    font-size: 28.8px;
    text-align: center;
  }
  .img-container {
    text-align: center;
    padding: 30px 0px;
  }
  .app-text {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    .mobile-apps {
      padding-top: 20px;
    }
  }
  .appleLogo {
    padding-right: 20px;
    height: 40px;
    width: 134px;
  }
  .playstoreLogo {
    height: 40px;
    width: 134px;
  }
  
  
  </style>
  