<template>
  <div class="bg-container">
    <div class="bg-orange">
      <h1 class="bg-text" id="forgotLogin">Forgot Username?</h1>
      <div class="svg-container">
        <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
          class="coh-inline-element">
          <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotUsernameBG'
}
</script>

<style scoped>
#forgotLogin {
  text-align: center;
}
</style>
