import axios from "axios";

const ENROLLMENTS_BASE_URL = process.env.VUE_APP_API_URL;
export default {

  /* first call for account recovery to send email */
  postAccountVerifyData(payload) {
    const endpoint = ENROLLMENTS_BASE_URL + "/account/recovery";

    return axios
      .post(endpoint, payload)
      .then((response) => {
        // console.log('---response---')
        // console.log(response)
        return response;
      })
      .catch((err) => {
        // console.log('--- postRecoveryData - err.response---')
        // console.log(err.response)
        return err.response;
      });
  },

  /* first call for forgot username to send email */
  postUsernameRecovery(payload) {
    const endpoint = ENROLLMENTS_BASE_URL + "/username/forgot";

    return axios
      .post(endpoint, payload)
      .then((response) => {
        // console.log('---response---')
        // console.log(response)
        return response;
      })
      .catch((err) => {
        // console.log('--- postRecoveryData - err.response---')
        // console.log(err.response)
        return err.response;
      });
  },

  /* second call for forgot username to display email using id */
  getUsername(id) {
    const endpoint = ENROLLMENTS_BASE_URL + "/username/forgot/" + id;

    return axios
      .get(endpoint)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  },
  
  /*call to validate the last 4 digits of SSN to show username */
  validateUsername(id,payload) {
    const endpoint = ENROLLMENTS_BASE_URL + "/username/forgot/" + id + "/validate"

    return axios
      .post(endpoint, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  },

  /* second call for account recovery to unlock account */
  postAccountRecoveryData(id) {
    const endpoint = ENROLLMENTS_BASE_URL + "/account/recovery/" + id;

    return axios
      .post(endpoint)
      .then((response) => {
        // console.log('---response---')
        // console.log(response)
        return response;
      })
      .catch((err) => {
        // console.log('--- postRecoveryData - err.response---')
        // console.log(err.response)
        // console.log(err.response)
        return err.response;
      });
  },
};
