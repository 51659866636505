// to organize routes
import FundingEnrollment from "@/views/FundingEnrollment/FundingEnrollment.vue";
import FundingLogin from "@/views/FundingEnrollment/FundingLogin.vue";

const FundingEnrollmentRoutes = {
  loadRoutes(routes) {
    routes.push(
      {
        path: "/enrollments/sso/:id",
        name: "Funding Enrollment",
        component: FundingEnrollment,
      },
      {
        path: "/funding-login",
        name: "Funding Login",
        component: FundingLogin,
      },
    );
    return routes;
  },
};

export default FundingEnrollmentRoutes;
