<template>
  <div id="processing-username">
    <the-header></the-header>
    <ForgotUserNameBG />

    <!-- Form Content-->
    <div class="form-container smaller-header" id="processing-username-page">
      <div class="noti-container ">
        <div class="overlay-info">
          <div class="headImgContainer">
            <img srcset="@/assets/images/login.png 1x, @/assets/images/login-2x.png 2x" src="@/assets/images/login-2x.png" alt="computer with browser and login fields illustration" />
          </div>
          <div class="bg-text1" id="main">
            <h3 class="bg-text-line">Your request has been submitted</h3>
            <p class="bg-text-line1">
              If the information provided is valid, an email will be sent to your email address on file to retrieve your username.
            </p>
            <p>If you do not receive an email within the next few minutes, please contact us at (888) 643-4343 for assistance.</p>
            <!-- <div class="bg-text-line2">
              <p>
                Need help? Contact Customer Service
                <br />
                Daily 7am–7pm HST
                <br />
                (808) 643-4343 (Oahu)
                <br />
                (888) 643-4343 (Toll Free)
              </p>
            </div> -->
          </div>

          <!-- Links -->
          <div class="bottomLinksContainer">
            <a :href="fhbOnline"
              id="processing-username-login-btn" data-test="processing-un-login-link">Return to login</a>
          </div>
        </div>
      </div>
    </div>

    <the-footer />
  </div>
</template>

<script>
import jQuery from 'jquery'
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import ForgotUserNameBG from '@/components/forgotusernamebg'

export default {
  name: 'ProcessingUsername',
  components: { TheHeader, TheFooter, ForgotUserNameBG },
  data() {
    return {
        fhbOnline: process.env.VUE_APP_FHB_ONLINE_URL
    }
  },
  mounted() {
    jQuery('html, body').animate({ scrollTop: 0 })
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

.headImgContainer {
  position: relative;
  height: auto;
  text-align: center;
}

.noti-spacing.noti-container {
  padding-top: 40px;
}

img {
  padding-top: 30px;
  padding-bottom: 15px;
  height: auto;
  width: 93.5px;
}

.bg-text-line {
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 10px;
  text-align: center;
}

.bg-text1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-text-line1 {
  margin: 0 0 0 0;
  font-weight: 450;
  font-size: 17px;
  text-align: center;
}

// .bg-text-line2 {
//   margin: 0 0 0 0;
//   font-weight: 450;
//   font-size: 17px;
//   text-align: center;
// }

button {
  height: 55px;
  width: 277px;
  position: relative;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 40px;
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;
}

/* a:hover,
  a:active, */
button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.submitBtn {
  position: relative;
  text-align: center;
  padding-bottom: 50px;
  padding-top: 10px;
}

.bottomLinksContainer {
  display: grid;
  padding-top: 10px;
  justify-content: center;
  text-align: center;
  row-gap: 15px;
}
#processing-username-login-btn{
  font-size: 17px;
}
</style>
