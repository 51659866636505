<template>
  <Loading v-if="loading" />
  <div v-else id="username-info-page">
    <the-header />
    <div class="bg-container">
      <div class="bg-orange">
        <h1 v-if="!needsValidation" id="displayUsernameHeader">Your Username</h1>
        <h1 v-if="needsValidation" id="displayUsernameHeader">Forgot Username?</h1>
        <div class="svg-container">
          <svg aria-hidden="true" height="100%" width="100%" preserveAspectRatio="none" viewBox="0 0 100 10"
            class="coh-inline-element">
            <polygon points="0,0 0,100 100,10 0,0" class="coh-inline-element"></polygon>
          </svg>
        </div>
      </div>
    </div>

    <!-- Username Display -->
    <div v-if="!needsValidation" class="form-container smaller-header" id="display-username">
      <div class="un-noti-container">
        <div class="un-overlay-info">
          <div class="ll-item ll-username">
            <div class="usernameLabel" id="main">
              <label for="username">Username</label>
            </div>
            <div class="input">
              <input aria-required="true" aria-labelledby="username" name="username" class="ll-textfield tf-lname"
                id="username" :value="username" data-test="display-un-multi" readonly />
              <button type="button" id="username-copy-button" class="copy-button" @click='toCopy'
                data-test="display-un-copy-multi" aria-label="copy username">
                <img srcset="@/assets/images/copy.png 1x, @/assets/images/copy-2x.png 2x" src='@/assets/images/copy.png'
                  alt="copy icon" />
              </button>
            </div>
          </div>

          <div class="noti-container">
            <div id="copied" class="copy-noti" v-if="isCopied" data-test="display-un-copy-noti">Copied!</div>
          </div>

          <!-- Form buttons -->
          <div class="formBtns">
            <button type="button" :class="toLogin ? 'processing' : null" @click="goToLogin"
              id="display-username-to-login" data-test="display-un-copy-login-btn">
              {{ loginBtn }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-container" v-if="needsValidation">
      <form @submit.prevent="validatePage">

        <div>
          </div>
          <div id="contextInfo">Please provide the last 4 digits of your Social Security Number or Business TIN to view your username. If you do not have an SSN or TIN, please contact us at (888) 643-4343 for assistance.</div>
          <div class="row"> 
            <input class="ssn-input" aria-required="true" name="applicant-email" data-test="forgot-un-email" id="last_ssn" type="text"
            v-model="last_ssn" v-on:blur="validateLastSSN" :class="
            missingLastSSN || invalidLastSSN ? 'll-textfield-error' : 'll-textfield'" />
          </div>
          <div>
            <div v-if="missingLastSSN|| invalidLastSSN" class="form-error-msg err-container" data-test="forgot-un-email-err">
              <img class="form-error-icon" srcset="
                  @/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg-2x.png" aria-hidden="true" />
              <div role="alert" class="form-error-txt" aria-label="Last SSN address error">
                Please enter your last 4 digits of your SSN or TIN
              </div>
            </div>
          </div>
        <!-- Empty space to prevent misclick on submit button -->
        <div v-if="!missingLastSSN && !invalidLastSSN" class="emptySpace" />

        <!-- Form buttons -->
        <div class="formBtns">
          <button type="submit" :class="allowToRecover ? 'processing' : null" id="frgt-username-submit-btn"
            data-test="forgot-un-submit">
            {{ continueBtn }}
          </button>
        </div>
        <Modal v-show="showErrModal" @close="closeModal" />
      </form>
    </div>

    <the-footer />
  </div>
</template>

<script>
import jQuery from 'jquery'
import TheHeader from '@/components/header'
import TheFooter from '@/components/footer'
import usernameRecovery from '@/app/recovery'
import Loading from '@/components/Loading'
import Modal from "@/components/SomethingWrongModal.vue"
import Validator from '@/app/validation'

export default {
  name: 'UsernameInfo',
  components: { TheFooter, TheHeader, Loading, Modal },
  data() {
    return {
      continueBtn: 'CONTINUE',
      loginBtn: 'GO TO LOGIN',
      errContainer: [],
      isCopied: false,
      showErr: false,
      toLogin: false,
      loading: false,
      username: "",
      needsValidation: false,
      missingLastSSN: false,
      invalidLastSSN: false,
      last_ssn: "",
      showErrModal: false,
      allowToRecover: false,
      id: "",
    }
  },
  methods: {
    // get username using ID received as a param
    async getUsername(id) {
      const resp = await usernameRecovery.getUsername(id)

      if (resp && resp.data) {
        if (resp.data.message === 'OK' && resp.status === 200) {
          console.log(resp)
          this.showErr = false
          if (resp.data.response) {
            this.needsValidation = false
          this.username = resp.data.response
          } else {
            this.needsValidation = true
          }
        } else {
          // Boolean to navigate to next page set to true
          this.showErr = true
        }
      }
    },

    goToLogin() {
      this.toLogin = true
      this.loginBtn = 'HEADING TO LOGIN...'
      location.href = process.env.VUE_APP_FHB_ONLINE_URL
    },

    // function used to copy usernames
    toCopy() {
      this.isCopied = true
      const copyUsername = document.getElementById('username')
      copyUsername.select()

      // to handle large amount of usernames to be copied
      copyUsername.setSelectionRange(0, 9999)
      navigator.clipboard.writeText(copyUsername.value)

      // timer for notification
      setTimeout(() => {
        this.isCopied = false
      }, 2000)
    },

    closeModal() {
      this.showErrModal = false;
    },

    //validate last 4 digits of SSN
    validateLastSSN() {
      // remove any spaces in input
      this.missingLastSSN = !this.last_ssn
      if (!this.missingLastSSN) {
        this.invalidLastSSN = !Validator.isValidLast4SSN(this.last_ssn)
      }
    },

   // check allow to pass before submit
   checkBeforeSubmit() {
      if (
        this.invalidLastSSN ||
        this.missingLastSSN
      ) {
        this.allowToRecover = false
      } else {
        this.allowToRecover = true
      }
    },

    // change submit button status
    handleButtonStatus() {
      if (this.allowToRecover) {
        this.continueBtn = 'PROCESSING...'
      } else {
        this.continueBtn = 'CONTINUE'
      }
    },

       // Handles whether or not api call should be made
    // If form is not complete, api call is does not occur
    handleContinue() {
      const payload = {
        last_digits_ssn: this.last_ssn
      }
      this.proxyCallErr = false
      if (this.allowToRecover) {
        // disable inputs on form on submission
        document.getElementById('last_ssn').setAttribute('disabled', '')
        this.validateUsername(payload)
      }
    },

    //Proxy call always returns 'OK' status
      async validateUsername(payload) {
      const resp = await usernameRecovery.validateUsername(this.id,payload)

      if (resp && resp.data) {
        if (resp.data.message === 'OK' && this.allowToRecover) {
          if (!resp.data.response) {
            this.$router.push({ name: 'invalid-username' })
          }
          this.username = resp.data.response
          this.needsValidation = false
  
        } else {
          this.allowToRecover = false
          this.continueBtn = 'CONTINUE'
          this.showErrModal = true
          document.getElementById('last_ssn').removeAttribute("disabled");
        }
        // if server times out or no response is received. 
        this.allowToRecover = false
        this.continueBtn = 'CONTINUE'
        this.showErrModal = true
        document.getElementById('last_ssn').removeAttribute("disabled");
      }
    },

   // validate error to scroll Top or focus on single input
   checkErr() {
      if (
        this.errContainer.length > 0
          ? jQuery('html, body').animate({ scrollTop: 0 })
          : null
      ) {
        this.errContainer = []
      }
      if (this.errContainer.length === 1) {
        document.getElementById(this.errContainer[0]).focus()
        this.errContainer = []
      } else if (this.errContainer.length === 0) {
        this.errContainer = []
      }
    },

    // final validation before submitting form
    validatePage() {
      this.checkBeforeSubmit()
      this.validateLastSSN()
      this.checkErr()
      this.handleButtonStatus()
      this.handleContinue()
    },
  },


  mounted() {
    jQuery('html, body').animate({ scrollTop: 0 })
  },
  async created() {
    // on load, get username
    this.loading = true
    await this.getUsername(this.$route.params.id)
    this.id = this.$route.params.id

    // if no errors, load page. 
    // Send user to invalid page if error occurs or if no usernames returned
    if (this.showErr === false) {
      setTimeout(() => {
        this.loading = false
      }, 3000)
    } else {
      setTimeout(() => {
        this.$router.push({ name: 'invalid-username' })
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";


label {
  color: #333333;
  display: inline-block;
  margin: 5px 0 15px;
  margin-top: 5px;
  font-weight: bold;
}

button {
  height: 55px;
  width: 220px;
  background-color: #fec443;
  border: 1px solid white;
  color: #444444;
  cursor: pointer;
  letter-spacing: 0.029em;
  border-radius: 30px;
  margin-top: 50px;
  display: inline-block;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  text-align: center;
  font-feature-settings: "cpsp" on;


  @include media-breakpoint-down(md) {
    height: 55px;
    width: 185px;
    position: relative;
    background-color: #fec443;
    border: 1px solid white;
    color: #444444;
    cursor: pointer;
    letter-spacing: 0.029em;
    border-radius: 30px;
    margin-top: 20px;
    display: inline-block;
    font-weight: 700;
    line-height: 24px;
    align-items: center;
    text-align: center;
    font-feature-settings: "cpsp" on;
  }

}

button:hover,
button:active {
  background-color: #fed67b;
  border-color: white;
}

.formBtns {
  text-align: center;
}

::placeholder {
  color: #bbbbbb;
  opacity: 1;
}

.fhb-red {
  color: #a1252c;
}

.processing {
  color: #e7e7e7;
}

@media (max-width: 767.9px) {
  .form-container {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .bg-text {
    padding-top: 20px;
  }

  button {
    height: 55px;
    width: 175px;
    background-color: #fec443;
    border: 1px solid white;
    color: #444444;
    cursor: pointer;
    letter-spacing: 0.029em;
    border-radius: 30px;
    margin-top: 50px;
    display: inline-block;
    font-weight: 700;
    line-height: 24px;
    align-items: center;
    text-align: center;
    font-feature-settings: "cpsp" on;
  }
}

.usernameLabel {
  text-align: center;
  padding-bottom: 10px;
}

.noti-container {
  display: block;
  margin-top: 5px;
  margin-left: 85%;
  margin-right: 15%;
}

.copy-noti {
  transition: opacity 2s;
  animation: fadeIn 1s;
  height: 30px;
  width: 70px;
  background-color: black;
  opacity: 0.75;
  text-align: center;
  border-radius: 3px;
  color: white;
  padding: 3px;
  position: absolute;
}

// fade in effect for notification
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.75
  }
}

.space {
  width: 412.5;
}

#username{
  border-right-color: #b0b0b0;
}

// override button styles
.copy-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-top: 0px;
  height: 50px;
  width: 50px;
  background: none;
  cursor: pointer;
  border: none;
  margin-left: 0px;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-right: 2px solid;
  padding-top: 5px;
}

.copy-button img {
  height: auto;
  width: 30px;
}

.copy-button:hover,
.copy-button:active,
.copy-button:focus {
  background-color: #e7e7e7;
  // border-top: 2px solid;
  // border-bottom: 2px solid;
  // border-right: 2px solid;
}

.copy-button img {
  height: auto;
  width: 30px;
}

#displayUsernameHeader {
  padding-top: 75px;
  text-align: center;
}

.un-overlay-info {
  height: auto;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
  text-align: center;
  align-items: center;
  width: 100%;

  @include media-breakpoint-up(sm) {
    background: $white;
    max-width: 650px;
    box-shadow: 0px 0px 14px -3px;
    // border: rgba(0, 0, 0, 0.15);
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    margin-top: 0;
  }
}
#display-username{
  @include media-breakpoint-down(sm) {
    padding-top: 0;
    margin-top: 0;
  }
}
.un-noti-container {
  align-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 2em 0;
  padding: 2em 0;
  @include media-breakpoint-down(sm) {
    // padding-top: 0;
    margin-top: 0;
  }
}

@media (min-height: 1160px) {
  .fhb-footer {
    width: 100%;
    bottom: 0px;
    position: absolute;

  }
}

.row {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.err-container {
  margin: 0 auto;
  max-width: 400px;
}

.ssn-input {
  margin:0 auto;
  max-width: 400px;
  width: 100%;
}
.ssn {
  font-size: medium;
  font-weight: bold;
}

.emptySpace {
  position: relative;
  height: 26.6px;
}

input {
  display: block;
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  color: #333333;
  border: 1px solid #b0b0b0;
  background: #ffffff;
  box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
  padding: 14px;
  margin: none!important;
}

.input {
  display: inline-flex;
  width: 100%;
  border-right: none;
  margin: none;
  button{
    border: 1px solid #b0b0b0;
    color: #333333;
    box-shadow: inset 0px 1px 1px 1.5px rgba(176, 176, 176, 0.4);
    height: 42px;
    //border-left: none;
  }
}
</style>
