const loginURLs = [
  'https://fhbonline.fhb.com/firsthawaiianbankonline_test_733926_2/uux.aspx#/login', // staging
  'https://fhbonline.fhb.com/firsthawaiianbankonline/uux.aspx#/login' // prod
]
export default {
  PROD: 'prod',
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEV: 'dev',
  getEnv() {
    return process.env.VUE_APP_STAGE
  },
  isProd() {
    return (process.env.VUE_APP_STAGE == this.PRODUCTION)
  },
  isStaging() {
    return process.env.VUE_APP_STAGE == this.STAGING
  },
  isDev() {
    return process.env.VUE_APP_STAGE == this.DEV
  },
  InternalHideForm() {
    const hideForm = process.env.VUE_APP_INTERNAL_HIDE_FORM 
    console.log (this.getEnv())
    console.log("hideform", hideForm)
    
    if (this.isProd() || this.getEnv() == 'local') {
      if (hideForm) return hideForm == 'YES'
    }
    return false
  },  
  // login urls for tethered login based on environment
  enrolledLoginURL() {
    if (window.location.href.indexOf('dev.') > -1) {
        return loginURLs[0]
    } else if (window.location.href.indexOf('staging.') > -1) {
        return loginURLs[0]
    } else if (window.location.href.indexOf('localhost') > -1) {
        return loginURLs[0]
    } else {
        return loginURLs[1]
    }
},
}
