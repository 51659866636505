<template>
  <div class="wallpaper">
    <!-- Loading page -->
    <div v-show="loading" class="loadingPage">
      <LoadingPage />
      <h1>Processing your request...</h1>
      <p class="loadingText">Please wait while your application loads.</p>
    </div>
    <!-- EnhancedSecuity Page shows if loading is false -->
    <div class="content" v-show="!loading">
      <div v-show="!loading && methodsStatus === ''">
        <EnhancedSecuity />
      </div>
    </div>
  </div>
</template>

<script>
import EnhancedSecuity from './MFA/MFAenhancedSecurity.vue'
import LoadingPage from './MFA/LoadingPage.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MFAhomePage',
  components: {
    EnhancedSecuity,
    LoadingPage,
  },
  computed: {
    ...mapGetters('mfaData', ['loading', 'otpValidated', 'methodsStatus']),
  }
}
</script>

<style scoped>
.wallpaper {
  background-image: url("~@/assets/images/orange@2x.svg");
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fef5ee;
}

.content {
  width: max(529px, 40%);
  padding: 20px 30px 40px 30px;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.8);
}

@media only screen and (max-width: 600px) {
  .wallpaper {
    background-image: none;
  }

  .content {
    border: none;
  }
}

.loadingPage {
  text-align: center;
}

.loadingText {
  font-size: 18px;
  font-weight: 400;
}
</style>
