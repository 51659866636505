<template>
  <input type="text" placeholder="(###) ###-####" maxlength="10" :value="modelValue" @keydown="validateKeydown"
    @blur="formatInput"  @focus="removeMask" autocomplete="off"/>
</template>

<script>

export default {
  name: 'PhoneInput',
  props: {
    modelValue: String
  },
  emits: ['update:modelValue', 'blur'],
  data() {
    return {
      raw: '',
      formatted: ''
    }
  },
  computed: {
    cmdKey: {
      get() {
        if (this.getBrowser() === 'firefox') {
          return 224
        }
        return 91
      }
    }
  },
  methods: {
    getBrowser() {
      const agent = navigator.userAgent
      if (agent.match('/chrome|chromium|crios/i')) return 'chrome'
      if (agent.match('/firefox|fxios/i')) return 'firefox'
      if (agent.match('/safari/i')) return 'safari'
      if (agent.match('/edg/i')) return 'edge'
    },
    validateKeydown(e) {
      const key = e.key // event.key press

      // if non-number prevent typing
      if (
        !(
          key === 'Backspace' ||
          key === 'ArrowLeft' ||
          key === 'ArrowRight' ||
          key === 'Tab' ||
          key === 'Delete' ||
          e.keycode === this.cmdKey ||
          e.ctrlKey
        )
      ) {
        if (key.match('[^0-9cv]+')) {
          e.preventDefault()
        }
      }
    },
    formatInput(e) {
      // format number
      this.formatted = this.wearMask(e.target.value.replace(/\D/g, ''))
      this.$emit('update:modelValue', this.formatted)
      this.$emit('blur')
    },
    wearMask(str) {
      if (str === null || str.length === 0) {
        return ''
      }
      if (str.length > 10) {
        str = str.substring(0, 10)
      }
      return this.addPhoneSymbols(str)
    },
    removeMask(e) {
      this.raw = e.target.value
        .replace('(', '')
        .replace(')', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')
        .replace('+1', '')
      this.$emit('update:modelValue', this.raw)
    },
    addPhoneSymbols(str) {
      if (str.length < 3) {
        return `+1 (${str}`
      }
      const a = str.substring(0, 3)
      const b = str.substring(3, 6)
      if (str.length <= 6) {
        return `+1 (${a}) ${b}`
      }
      const c = str.substring(6, 10)
      const phone = `+1 (${a}) ${b}-${c}`
      return phone
    }
  }
}
</script>
